import React from "react";
import { useCypressWarning } from "../../hooks";
import { Error } from "../icons";
import Tooltip from "../Tooltip/Tooltip";

const getStateClasses = (disabled, error) => {
  if (disabled) {
    return "bg-base-50 border-base-300 text-base-500";
  } else if (error) {
    return "border-error-500 bg-error-50 outline-error-500 border-2 outline-2";
  }

  return "bg-white border-base-300 outline-primary-300 outline-1";
};

export default function Textarea({
  id,
  placeholder,
  error,
  title,
  helpText,
  value,
  disabled = false,
  rows = 5,
  onChange,
  tooltipMessage,
  variant = "light",
}) {
  useCypressWarning(id, `id missing for ${title} textarea component`);

  const labelTextClass = variant === "light" ? "text-black" : "text-white";
  const helpTextClass =
    variant === "light" ? "text-primary-800" : "text-primary-100";

  return (
    <div className="flex flex-col w-full relative">
      <label
        data-cy={`${id}-label`}
        htmlFor={id}
        className={"mb-2 text-sm font-normal " + labelTextClass}
      >
        {title}
      </label>
      {tooltipMessage && (
        <div className="flex sm:hidden absolute items-center top-0 right-0">
          <Tooltip message={tooltipMessage}>
            <Error className="text-base-900" />
          </Tooltip>
        </div>
      )}
      <div className="flex flex-col relative">
        <textarea
          name={id}
          rows={rows}
          disabled={disabled}
          className={`px-2 py-2 text-base-500 text-sm font-normal border mt-2 placeholder-base-500 ${getStateClasses(
            disabled,
            error
          )}`}
          placeholder={placeholder}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
          value={value}
        />
        {tooltipMessage && (
          <div className="hidden sm:flex absolute items-center mt-2 inset-y-0 -right-8">
            <Tooltip message={tooltipMessage}>
              <Error className="text-base-900" />
            </Tooltip>
          </div>
        )}
      </div>
      {helpText && (
        <p className={helpTextClass + " text-xs mt-3"}>{helpText}</p>
      )}
    </div>
  );
}
