import React, { useCallback, useMemo, useState } from "react";
import { navigate } from "gatsby";
import { get } from "lodash";
import { Prompt } from "components";
import { useApp, useAuth, useGraphql } from "hooks";
import {
  deleteAssetRegistrationMutation,
  saveAssetRegistrationMutation,
} from "../api/mutations";
import { sanitizeAssetRegistration } from "support/helpers";

const PROMPT_STATES = {
  hidden: 0,
  saveOrDiscard: 1,
  discardConfirmation: 2,
};

export default function useAssetRegistrationPrompt({
  id,
  formData,
  isEditing,
  isSaved,
  currentStep,
  totalSteps,
  loading = false,
}) {
  const { updateSavedRegistrationCount } = useAuth();
  const { addMessage } = useApp();
  const [promptState, setPromptState] = useState(PROMPT_STATES.hidden);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const { executeQuery: executeSaveAssetRegistrationMutation } = useGraphql(
    saveAssetRegistrationMutation
  );

  const { executeQuery: executeDeleteAssetRegistrationMutation } = useGraphql(
    deleteAssetRegistrationMutation
  );

  const showPrompt = useCallback(() => {
    if (!loading) {
      setPromptState(PROMPT_STATES.saveOrDiscard);
    }
  }, [setPromptState, loading]);

  const AssetRegistrationPrompt = useMemo(() => {
    return () => (
      <>
        <Prompt
          icon="warning"
          title="Do you want to save your registration for later?"
          actionText="Save & Exit"
          cancelActionText="Close & Discard"
          loading={loadingSave}
          disabledCancel={loadingSave}
          onCancel={() => setPromptState(PROMPT_STATES.discardConfirmation)}
          onClose={() => setPromptState(PROMPT_STATES.hidden)}
          show={promptState === PROMPT_STATES.saveOrDiscard}
          content={
            <p>By pressing save, you can complete the form at a later date.</p>
          }
          onAction={async () => {
            setLoadingSave(true);

            try {
              const serialNumber = get(formData, "assetInfo.serialNumber");
              const currentProgress = isEditing
                ? 100
                : Math.min(
                    100,
                    Math.trunc(((currentStep.index + 1) / totalSteps) * 100)
                  );

              if (serialNumber) {
                await executeSaveAssetRegistrationMutation({
                  assetRegistration: {
                    id,
                    serialNumber,
                    friendlyName: get(formData, "assetInfo.friendlyName"),
                    formData: sanitizeAssetRegistration(formData),
                    currentStep,
                    currentProgress,
                    isEditing,
                  },
                });

                if (!isSaved) {
                  // this is a new registration, increase the saved registration count
                  updateSavedRegistrationCount(1);
                }
              }
            } catch (error) {
              addMessage(
                "error",
                "An error occurred when saving your registration.  If the issue persists, please contact our Connect support team."
              );
            }

            setLoadingSave(false);

            navigate("/app/dashboard");
          }}
        />
        <Prompt
          icon="warning"
          title="Are you sure you want to discard the registration?"
          actionText="Discard"
          cancelActionText="Keep"
          loading={loadingDelete}
          disabledCancel={loadingDelete}
          onCancel={() => setPromptState(PROMPT_STATES.hidden)}
          onClose={() => setPromptState(PROMPT_STATES.hidden)}
          show={promptState === PROMPT_STATES.discardConfirmation}
          onAction={async () => {
            if (isSaved) {
              // this is a saved registration, delete the saved registration
              setLoadingDelete(true);

              try {
                await executeDeleteAssetRegistrationMutation({
                  id,
                });

                updateSavedRegistrationCount(-1);
              } catch (error) {
                addMessage(
                  "error",
                  "An error occurred when saving your registration.  If the issue persists, please contact our Connect support team."
                );
              }

              setLoadingDelete(false);
            }

            navigate("/app/dashboard");
          }}
        />
      </>
    );
  }, [loadingSave, loadingDelete, promptState]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    AssetRegistrationPrompt,
    showPrompt,
  };
}
