import React, { Fragment } from "react";
import { useContext } from "react";
import { LocaleContext } from "../../contexts/LocaleContext";
import UnitedKingdom from "images/flags/united_kingdom.svg";
import Ireland from "images/flags/ireland.png";
import UnitedStates from "images/flags/united_states.png";
import Canada from "images/flags/canada.png";
import { LOCALE_IE, LOCALE_UK, LOCALE_CA, LOCALE_US } from "support/constants";
import { Listbox } from "@headlessui/react";
import { PickerArrowDown } from "../icons";

export default function LanguageSwitcher({ variant = "default" }) {
  const { setLocale, locale } = useContext(LocaleContext);

  const options = [
    { id: 1, name: "GB", locale: LOCALE_UK, flag: UnitedKingdom },
    { id: 2, name: "ROI", locale: LOCALE_IE, flag: Ireland },
    { id: 3, name: "US", locale: LOCALE_US, flag: UnitedStates },
    { id: 4, name: "CA", locale: LOCALE_CA, flag: Canada },
  ];

  const handleSetLocale = (locale) => {
    setLocale(locale);
  };

  if (variant === "vertical") {
    return (
      <>
        {options.map((option) => {
          return (
            <div key={option.id} className="mb-4">
              <img
                className="inline-block cursor-pointer mr-2"
                src={option.flag}
                alt={`${option.name}-flag`}
                width={40}
                onClick={() => {
                  handleSetLocale(option.locale);
                }}
              />
              {option.name}
            </div>
          );
        })}
      </>
    );
  }

  return (
    <Listbox value={locale}>
      <Listbox.Button className="text-white w-full flex flex-row">
        <img
          className="inline-block cursor-pointer mr-2"
          src={
            options.find((x) => {
              return x.locale === locale;
            }).flag
          }
          alt="flag"
          width={40}
        />

        <div className="grow">
          {
            options.find((x) => {
              return x.locale === locale;
            }).name
          }
        </div>

        <div className="right-0">
          <PickerArrowDown aria-hidden="true" />
        </div>
      </Listbox.Button>
      <Listbox.Options className="mt-2 fixed">
        {options.map(
          (option) =>
            option.locale !== locale && (
              <Listbox.Option
                key={option.id}
                value={option.locale}
                as={Fragment}
                className="p-2 first-of-type:rounded-t last-of-type:rounded-b cursor-pointer"
                onClick={() => {
                  handleSetLocale(option.locale);
                }}
              >
                {({ active }) => (
                  <li
                    className={`${
                      active ? "bg-base-100 text-black" : "bg-white text-black"
                    }`}
                  >
                    <img
                      className="inline-block cursor-pointer mr-2"
                      src={option.flag}
                      alt={`${option.name}-flag`}
                      width={40}
                    />
                    {option.name}
                  </li>
                )}
              </Listbox.Option>
            )
        )}
      </Listbox.Options>
    </Listbox>
  );
}
