import React from "react";
import { Error, Tick } from "../icons";

const Icon = ({ icon }) => {
  switch (icon) {
    case "tick":
      return <Tick className="mr-4 text-base-900" />;
    case "error":
      return <Error className="mr-4 text-base-900" />;
    default:
      console.warn("Alert icon is not supported");
      return null;
  }
};

const DismissButton = ({ className, variant, onDismiss }) => (
  <button
    className={`mt-0.5 cursor-pointer text-sm font-bold uppercase text-${variant}-500 ${className || ""
      }`}
    onClick={onDismiss}
  >
    Close
  </button>
);

const Alert = ({
  variant = "base",
  icon,
  title,
  renderTitle,
  body,
  fadeOut = false,
  onDismiss,
  children,
}) => (
  <>
    <div
      className={`border rounded p-4 bg-${variant}-50 border-${variant}-500 transition-opacity duration-1000 ${fadeOut ? "opacity-0" : "opacity-100"
        }`}
    >
      <div className="flex flex-row">
        {icon && (
          <div>
            <Icon icon={icon} />
          </div>
        )}
        <div className="grow mr-4">
          <p className="mt-0.5 text-sm">{renderTitle ? renderTitle() : title}</p>
          {body && <p className="text-sm mt-4">{body}</p>}
        </div>
        {onDismiss && (
          <DismissButton
            className="hidden sm:block"
            variant={variant}
            onDismiss={onDismiss}
          />
        )}
      </div>
      {children &&
        <div className="flex justify-center my-6">
          {children}
        </div>
      }
      {onDismiss && (
        <DismissButton
          className="mt-2 sm:hidden"
          variant={variant}
          onDismiss={onDismiss}
        />
      )}
    </div>
  </>
);

export default Alert;
