import React, { createContext, useEffect, useState, useMemo } from "react";
import { head } from "lodash";
import { useStaticQuery, graphql } from "gatsby";
import { useAuth } from "../hooks";
import { filterAnnouncementsForUser } from "../services/announcements";

export const AnnouncementsContext = createContext({
  announcements: [],
});

export const AnnouncementsProvider = ({ children }) => {
  const { user, isMaxAi, loadingSession } = useAuth();
  const [announcements, setAnnoucements] = useState([]);

  const result = useStaticQuery(graphql`
    query SiteAnnouncements {
      allContentfulSiteAnnouncements(
        filter: { node_locale: { eq: "en-US" } }
        sort: { updatedAt: DESC }
      ) {
        nodes {
          id
          bannerMessage
          fullAnnouncement
          shortBannerMessage
          showTo
          updatedAt
          video
        }
      }
    }
  `);

  const firstAnnouncement = useMemo(() => head(announcements), [announcements]);

  useEffect(() => {
    if (!loadingSession) {
      const filteredAnnoucements = filterAnnouncementsForUser(
        user,
        isMaxAi,
        result.allContentfulSiteAnnouncements.nodes
      );
      setAnnoucements(filteredAnnoucements);
    }
  }, [loadingSession, user]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AnnouncementsContext.Provider
      value={{
        announcements,
        firstAnnouncement: firstAnnouncement,
        hasAnnouncements: announcements.length > 0,
      }}
    >
      {children}
    </AnnouncementsContext.Provider>
  );
};
