import React from "react";
import { ArrowLeft, ArrowRight } from "../icons";

export default function Pagination({
  currentPage,
  numPages,
  onPrevious,
  onNext,
}) {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === numPages;

  return (
    <div className="flex justify-center items-center bg-base-50 py-4 w-full mt-6">
      <div
        data-cy="pagination-previous"
        className={`${isFirstPage ? "pointer-events-none" : "cursor-pointer"}`}
        onClick={() => onPrevious()}
      >
        <ArrowLeft size={18} colour={isFirstPage ? "#C7C7C7" : "#8AC43F"} />
      </div>

      <p className="text-sm font-semibold text-black mx-16">
        {currentPage} OF {numPages}
      </p>

      <div
        data-cy="pagination-next"
        className={`${isLastPage ? "pointer-events-none" : "cursor-pointer"}`}
        onClick={() => onNext()}
      >
        <ArrowRight size={18} colour={isLastPage ? "#C7C7C7" : "#8AC43F"} />
      </div>
    </div>
  );
}
