import { useEffect } from "react";
import { navigate } from "gatsby";
import useAuth from "./useAuth";

export default function useAuthenticatedRoute() {
  const { isLoggedIn, loadingSession, user } = useAuth();

  useEffect(() => {
    if (!isLoggedIn && !loadingSession) {
      navigate("/login");
    } else if (isLoggedIn && !user.isApproved && !loadingSession) {
      navigate("/app/dashboard");
    } else if (isLoggedIn && !user.termsConditionsAccepted) {
      navigate("/app/dashboard");
    }
  }, [isLoggedIn, loadingSession, user.isApproved]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}
