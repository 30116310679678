import { useLocation } from "@gatsbyjs/reach-router";

export default function useQueryParams() {
  const { search } = useLocation();

  if (!search) {
    return {};
  }

  const params = search.substring(1).split("&");

  return params.reduce((acc, i) => {
    let [key, val] = i.split("=");

    return { ...acc, [key]: val };
  }, {});
}
