import React, { useCallback, useState } from "react";
import { navigate } from "gatsby";
import { first } from "lodash";
import { Alert, Header, Footer, PageMeta, Prompt } from "components";
import {
  useApp,
  useAuth,
  useBasket,
  useGraphql,
  useRegistrations,
} from "hooks";
import { acknowledgeFailedRegistrationMutation } from "api/mutations";

function Notifications({ messages, dismissMessage }) {
  return (
    <div className="fixed top-8 sm:right-4 sm:mx-0 mx-4 z-50">
      {messages.map((message) => (
        <div key={message.id} className="mb-2">
          <Alert {...message} onDismiss={() => dismissMessage(message.id)} />
        </div>
      ))}
    </div>
  );
}

export default function Layout({
  onToggleMenu,
  meta = {},
  children,
  hideFooter = false,
}) {
  const { messages, dismissMessage } = useApp();
  const { user, logout } = useAuth();
  const { basketItemCount } = useBasket();

  const { executeQuery: executeAcknowledgeFailedRegistrationQuery } =
    useGraphql(acknowledgeFailedRegistrationMutation);

  const [showLogoutPrompt, setShowLogoutPrompt] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const { failedRegistrations, loadFailedRegistrations } = useRegistrations();

  let failedRegistrationPromptData = {
    display: false,
    id: "",
    serialNumber: "",
    friendlyName: "",
  };

  if (failedRegistrations.length > 0) {
    const failedRegistration = first(failedRegistrations);
    failedRegistrationPromptData = {
      display: true,
      id: failedRegistration.id,
      serialNumber: failedRegistration.serialNumber,
      friendlyName: failedRegistration.friendlyName,
    };
  }

  const handleFailedRegistrationAcknowledgement = useCallback(
    async (assetRegistrationId) => {
      setRequestInProgress(true);

      try {
        await executeAcknowledgeFailedRegistrationQuery({
          assetRegistrationId,
        });

        await loadFailedRegistrations();
      } catch (error) {
        console.warn("Error when acknowledging failed registration", error);
      }

      setRequestInProgress(false);
    },
    [executeAcknowledgeFailedRegistrationQuery, loadFailedRegistrations]
  );

  return (
    <div className="min-h-full flex flex-col relative">
      <Prompt
        icon="warning"
        title="Are you sure you want to log out?"
        actionText="Log Out"
        cancelActionText="Go Back"
        onClose={() => setShowLogoutPrompt(false)}
        show={showLogoutPrompt}
        onAction={async () => {
          await logout();
          navigate("/");
        }}
      />
      <Prompt
        icon="warning"
        title={`Registration of ${failedRegistrationPromptData.friendlyName} (${failedRegistrationPromptData.serialNumber}) was unsuccessful. Please contact the Connect support team for guidance.`}
        cancelActionText="OK"
        loadingCancel={requestInProgress}
        disabledCancel={requestInProgress}
        onClose={() =>
          handleFailedRegistrationAcknowledgement(
            failedRegistrationPromptData.id
          )
        }
        show={failedRegistrationPromptData.display}
      />
      <PageMeta {...meta} />
      <Header
        username={user.username}
        shoppingCartItemCount={basketItemCount}
        hideCart={!user.isApproved}
        isApproved={user.isApproved}
        onLogout={() => setShowLogoutPrompt(true)}
        onToggleMenu={onToggleMenu}
      />
      <div className="flex flex-col flex-grow">{children}</div>
      {!hideFooter && (
        <Footer
          contactNumber="0330 333 1513"
          contactEmail="InstallerConnect.Enquiries@installerconnect.com"
        />
      )}
      <Notifications messages={messages} dismissMessage={dismissMessage} />
    </div>
  );
}
