import React, { useCallback, useMemo, useState } from "react";
import { navigate } from "gatsby";
import { Prompt } from "components";
import { useApp, useAuth, useGraphql } from "hooks";
import { submitBenchmarkRegistrationMutation } from "../api/mutations";
import { isEmpty, get } from "lodash";

const PROMPT_STATES = {
  hidden: 0,
  saveOrDiscard: 1,
  discardConfirmation: 2,
};

export default function useBenchmarkRegistrationPrompt({
  formData,
  submitting = false,
}) {
  const { addMessage } = useApp();
  const { user } = useAuth();

  const [promptState, setPromptState] = useState(PROMPT_STATES.hidden);
  const [loadingSave, setLoadingSave] = useState(false);

  const { executeQuery: executeBenchmarkRegistrationMutation } = useGraphql(
    submitBenchmarkRegistrationMutation
  );

  const showPrompt = useCallback(() => {
    if (!submitting) {
      setPromptState(PROMPT_STATES.saveOrDiscard);
    }
  }, [setPromptState, submitting]);

  const BenchmarkRegistrationPrompt = useMemo(() => {
    return () => (
      <>
        <Prompt
          icon="warning"
          title="Do you want to save your registration for later?"
          actionText="Save & Exit"
          cancelActionText="Close & Discard"
          loading={loadingSave}
          disabledCancel={loadingSave}
          onCancel={() => setPromptState(PROMPT_STATES.discardConfirmation)}
          onClose={() => setPromptState(PROMPT_STATES.hidden)}
          show={promptState === PROMPT_STATES.saveOrDiscard}
          content={
            <p>By pressing save, you can complete the form at a later date.</p>
          }
          onAction={async () => {
            setLoadingSave(true);
            const benchmarkRegistration =
              await executeBenchmarkRegistrationMutation({
                benchmarkRegistration: JSON.stringify({
                  ...formData,
                  installer_uuid: user.benchmarkEngineerId,
                  status: 0,
                }),
              });

            setLoadingSave(false);

            if (!isEmpty(benchmarkRegistration.errors)) {
              addMessage(
                "error",
                "An error occurred when saving your benchmark registration.  If the issue persists, please contact our Connect support team."
              );

              setPromptState(PROMPT_STATES.hidden);
            }

            if (
              !isEmpty(
                get(benchmarkRegistration, "data.submitBenchmarkRegistration")
              )
            ) {
              navigate("/app/my-registrations-history");
            }
          }}
        />
        <Prompt
          icon="warning"
          title="Are you sure you want to discard the registration?"
          actionText="Discard"
          cancelActionText="Keep"
          onCancel={() => setPromptState(PROMPT_STATES.hidden)}
          onClose={() => setPromptState(PROMPT_STATES.hidden)}
          show={promptState === PROMPT_STATES.discardConfirmation}
          onAction={() => navigate("/app/my-registrations-history")}
        />
      </>
    );
  }, [loadingSave, promptState]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    BenchmarkRegistrationPrompt,
    showPrompt,
  };
}
