/**
 * Uses react-intl. Should use built in components if possible
 * https://formatjs.io/docs/react-intl/components
 *
 * Otherwise this file provides wrappers around the imperative api
 * https://formatjs.io/docs/react-intl/api
 *
 * Pass intl as the first argument
 */
import { LOCALE_UK, LOCALE_CA, LOCALE_US, LOCALE_IE } from "support/constants";
import UnitedKingdom from "images/flags/united_kingdom.svg";
import Ireland from "images/flags/ireland.png";
import UnitedStates from "images/flags/united_states.png";
import Canada from "images/flags/canada.png";
import React from "react";

export function getTranslationMessage(
  { intl, id, defaultMessage = null },
  values = {}
) {
  const descriptor = {
    id,
  };

  if (defaultMessage) {
    descriptor.defaultMessage = defaultMessage;
  }

  return intl.formatMessage(descriptor, values);
}

export const getCurrentFlag = (locale) => {
  switch (locale) {
    default:
    case LOCALE_UK:
      return (
        <img
          className="inline-block cursor-pointer"
          src={UnitedKingdom}
          alt="united-kingdom-flag"
          width={40}
        />
      );
    case LOCALE_IE:
      return (
        <img
          className="inline-block cursor-pointer"
          src={Ireland}
          alt="ireland-flag"
          width={40}
        />
      );
    case LOCALE_US:
      return (
        <img
          className="inline-block cursor-pointer"
          src={UnitedStates}
          alt="united-states-flag"
          width={40}
        />
      );
    case LOCALE_CA:
      return (
        <img
          className="inline-block cursor-pointer"
          src={Canada}
          alt="canada-flag"
          width={40}
        />
      );
  }
};
