import React from "react";
import { Loading, UploadFileIcon } from "../icons";

const UploadImageFile = ({ image, setImage, loading }) => {
  return (
    <label className="flex items-center bg-white rounded-lg py-2 px-4 cursor-pointer hover:bg-base-100 w-full">
      <UploadFileIcon />
      <p className="ml-4 truncate text-sm">
        {image.name ? image.name : "Choose file to upload"}
      </p>
      {loading && <Loading />}
      <input
        id="image"
        type="file"
        className="hidden"
        accept="image/png, image/jpeg"
        onChange={(e) => setImage(e.target.files[0])}
      />
    </label>
  );
};

export default UploadImageFile;
