import React from "react";

function determineRegistrationText(bandDetails) {
    const {
        currentBand,
        nextBand,
        registrationsUntilNextBand,
        registrationsThisYear
    } = bandDetails;

    if (currentBand.toLowerCase() === "gold") {
        return `You are gold. You have ${registrationsThisYear} installations this year`;
    }
    if  (registrationsUntilNextBand === 0 || nextBand === undefined || nextBand === null) {
        return `You have ${registrationsThisYear} installations this year`;
    }

    return `${registrationsUntilNextBand} more installs to ${nextBand}`;
}

export default function LoyaltyBandProgressBar({bandDetails}) {
    const {
        percentageToNextBand: progressPercentage,
        currentBand,
    } = bandDetails;

  const loyaltyProgressBarBackgroundColours = {
    bronze: { name: "bg-bronze-500", value: "#9F7330" },
    silver: { name: "bg-silver-500", value: "#A6A9AA" },
    gold: { name: "bg-gold-500", value: "#DAA520" },
  };

  const backgroundColour = loyaltyProgressBarBackgroundColours[currentBand.toLowerCase()];

  const registrationText = determineRegistrationText(bandDetails);

  return (
    <div className="relative w-5/6 bg-primary-400 p-2 flex items-center">
      <div
        className={`${backgroundColour.name} p-7`}
        style={{ width: `${progressPercentage}%` }}
      />
      <div
        className={progressPercentage >= 100 ? "hidden" : "block"}
        style={{
          borderRight: "40px solid transparent",
          borderTop: "56px solid",
          borderTopColor: [backgroundColour.value],
        }}
      />
      <div
        className={`absolute ${
          currentBand.toLowerCase() === "bronze" ? "text-white" : "text-primary-900"
        }`}
      >
        <p className="hidden md:block ml-2 p-4">
          {registrationText}
        </p>
        <p className="md:hidden ml-2 p-4">
          {registrationText}
        </p>
      </div>
    </div>
  );
}
