import React, { createContext, useCallback, useEffect, useState } from "react";
import { get } from "lodash";
import { useAuth, useGraphql } from "../hooks";
import { getMyInstallerUpdateRequestsQuery } from "../api/queries";
import { updateMyInstallerAccountMutation } from "../api/mutations";

const PROFILE_TO_USER_MAPPING = {
  gasSafeRegistrationNumber: "gasSafeNumber",
  cpsName: "cpsName",
  companyName: "name",
  companyHouseNumber: "houseNumber",
  companyStreet: "street1",
  companyCity: "city",
  companyPostcode: "postCode",
  companyCountry: "countryCode",
  companyRegion: "region",
  companyEmail: "businessEmail",
  companyTelephone: "phone",
  companyWebsite: "website",
  companyFurtherInfo: "furtherInformation",
  installerFirstName: "firstName",
  installerLastName: "lastName",
  installerEmail: "contacts[0].installerConnectEmail",
  installerTelephone: "contacts[0].phone",
  installerMobile: "contacts[0].mobilePhone",
  maxAiStatus: "maxAiStatus",
};

export const ProfileContext = createContext({
  loadingProfile: false,
  profile: {},
  updateProfile: () => {},
  isUpdatingProfile: false,
  pendingFields: [],
});

export const ProfileProvider = ({ children }) => {
  const { loadingSession, refreshUserState, user } = useAuth();
  const [loadingProfile, setLoadingProfile] = useState(true);

  const {
    data: installerAccountRequests,
    executeQuery: executeGetMyInstallerAccountRequestsQuery,
  } = useGraphql(getMyInstallerUpdateRequestsQuery);

  const { loading, executeQuery: executeUpdateMyInstallerAccountMutation } =
    useGraphql(updateMyInstallerAccountMutation);

  const fetchInstallerUpdateAccountRequest = useCallback(async () => {
    await refreshUserState();
    await executeGetMyInstallerAccountRequestsQuery();
    setLoadingProfile(false);
  }, [refreshUserState, executeGetMyInstallerAccountRequestsQuery]);

  const updateProfile = useCallback(
    async (form) => {
      const response = await executeUpdateMyInstallerAccountMutation({
        installerDetails: form,
      });

      const errors = response.errors || [];
      if (errors.length > 0) {
        throw new Error(response.errors.map(({ message }) => message).join());
      }

      await fetchInstallerUpdateAccountRequest();
    },
    [
      executeUpdateMyInstallerAccountMutation,
      fetchInstallerUpdateAccountRequest,
    ]
  );

  useEffect(() => {
    if (!loadingSession) {
      fetchInstallerUpdateAccountRequest();
    }
  }, [loadingSession, fetchInstallerUpdateAccountRequest]);

  const updateRequest = get(
    installerAccountRequests,
    "getMyInstallerUpdateRequests[0]",
    {}
  );

  const profile = {};
  const pendingFields = [];
  const profileKeys = Object.keys(PROFILE_TO_USER_MAPPING);

  for (const profileKey of profileKeys) {
    const currentValue = get(user, PROFILE_TO_USER_MAPPING[profileKey]);
    const updatedValue = get(updateRequest, profileKey);

    if (updatedValue !== undefined && currentValue !== updatedValue) {
      pendingFields.push(profileKey);
    }

    profile[profileKey] = updatedValue || currentValue || "";
  }

  return (
    <ProfileContext.Provider
      value={{
        loadingProfile,
        profile,
        updateProfile,
        isUpdatingProfile: loading,
        pendingFields,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
