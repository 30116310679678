import React, { useState } from "react";
import { ArrowLeft, ArrowRight } from "../icons";
import {
  endOfMonth,
  isSunday,
  isMonday,
  isToday,
  nextSunday,
  previousMonday,
  getMonth,
  getYear,
  addDays,
  getDate,
  format,
  isSameDay,
} from "date-fns";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const DatePickerHeader = ({ month, year, setMonth, setYear }) => {
  return (
    <div className="bg-primary-500 p-2 flex items-center justify-center rounded">
      <div
        className="cursor-pointer mr-auto ml-4"
        onClick={() => {
          let newMonth, newYear;
          if (month - 1 < 0) {
            newMonth = 11;
            newYear = year - 1;
            setMonth(newMonth);
            setYear(newYear);
          } else {
            newMonth = month - 1;
            newYear = year;
            setMonth(newMonth);
          }
        }}
      >
        <ArrowLeft size={16} colour="#8AC43F" />
      </div>
      <p className="text-white font-semibold">
        {monthNames[month]} {year}
      </p>
      <div
        className="cursor-pointer ml-auto mr-4"
        onClick={() => {
          let newMonth, newYear;

          if (month + 1 > 11) {
            newMonth = 0;
            newYear = year + 1;
            setMonth(newMonth);
            setYear(newYear);
          } else {
            newMonth = month + 1;
            newYear = year;
            setMonth(newMonth);
          }
        }}
      >
        <ArrowRight size={16} colour="#8AC43F" />
      </div>
    </div>
  );
};

const CalendarWeekBar = () => {
  return (
    <div className="grid grid-cols-7 gap-px text-center text-xs font-semibold leading-6 text-primary-500 mt-2">
      <p className="text-white">Mo</p>
      <p className="text-white">Tu</p>
      <p className="text-white">We</p>
      <p className="text-white">Th</p>
      <p className="text-white">Fr</p>
      <p className="text-white">Sa</p>
      <p className="text-white">Su</p>
    </div>
  );
};

const CalendarContent = ({ days, setSelectedDate }) => {
  return (
    <div className="flex text-xs leading-6">
      <div className="w-full grid grid-cols-7 gap-px text-white">
        {days.length > 0 &&
          days.map((day, i) => (
            <div
              key={day.date}
              className={`flex items-center justify-center px-3 py-1 m-1 rounded ${
                day.isSelectable
                  ? "bg-primary-500 cursor-pointer"
                  : "bg-primary-300"
              } ${!day.isCurrentMonth && day.isSelectable && "opacity-50"} ${
                day.isSelectable &&
                day.isSelected &&
                "outline outline-accent-500"
              }`}
              onClick={() => {
                if (day.isSelectable) {
                  setSelectedDate(format(new Date(day.date), "yyyy-MM-dd"));
                }
              }}
            >
              <time dateTime={day.date}>{getDate(day.date)}</time>
            </div>
          ))}
      </div>
    </div>
  );
};

export default function DatePicker({
  minDate,
  maxDate,
  selectedDate,
  setSelectedDate,
}) {
  const [month, setMonth] = useState(
    selectedDate ? getMonth(new Date(selectedDate)) : getMonth(new Date())
  );
  const [year, setYear] = useState(
    selectedDate ? getYear(new Date(selectedDate)) : getYear(new Date())
  );

  const calendarStartDate = (year, month) => {
    const monthStartDate = new Date(year, month, 1);
    return isMonday(monthStartDate)
      ? monthStartDate
      : previousMonday(monthStartDate);
  };

  const calendarEndDate = (year, month) => {
    const monthEndDate = endOfMonth(new Date(year, month, 1));
    return isSunday(monthEndDate) ? monthEndDate : nextSunday(monthEndDate);
  };

  const calendar = (calendarStartDate, calendarEndDate, month) => {
    let calendarDays = [];
    let currentDay = calendarStartDate;

    while (currentDay < calendarEndDate) {
      calendarDays.push({
        date: currentDay,
        isCurrentMonth: getMonth(currentDay) === month,
        isSelectable: currentDay >= minDate && currentDay < maxDate,
        isSelected: selectedDate
          ? isSameDay(new Date(currentDay), new Date(selectedDate))
          : isToday(currentDay),
      });
      currentDay = addDays(currentDay, 1);
    }
    return calendarDays;
  };

  const days = calendar(
    calendarStartDate(year, month),
    calendarEndDate(year, month),
    month
  );

  return (
    <div className="bg-primary-400 p-2 rounded">
      <DatePickerHeader
        month={month}
        year={year}
        setMonth={setMonth}
        setYear={setYear}
      />
      <CalendarWeekBar />
      <CalendarContent days={days} setSelectedDate={setSelectedDate} />
    </div>
  );
}
