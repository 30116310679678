import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@gatsbyjs/reach-router";

export default function PageMeta({ title, description }) {
  const { pathname } = useLocation();
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);

  const metaTitle = title || siteMetadata.title;
  const metaDescription = description || siteMetadata.description;

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{metaTitle}</title>
      <link rel="canonical" href={`${siteMetadata.siteUrl}${pathname}`} />
      <meta name="description" content={metaDescription} />
    </Helmet>
  );
}
