import React from "react";
import Button from "../Button/Button";
import { navigate } from "gatsby";

export default function PromotionalProductItem({
  id,
  title,
  imageUrl,
  promotionText,
  href,
  children,
}) {
  return (
    <div
      className="w-full md:w-1/4 lg:w-1/5 mb-8 md:mb-0 flex flex-col justify-between text-primary-500 font-bold text-lg cursor-pointer"
      onClick={() => navigate(href)}
    >
      <div>
        <p>{title}</p>
      </div>
      {imageUrl && <img className="mt-4" src={imageUrl} alt="register" />}
      {children}
      <p className="mt-2">{promotionText}</p>
      <Button
        id={id || "register"}
        title="Register"
        variant="primary"
        fillStyle="fill"
        className="mt-4 md:w-1/3 w-full"
        href={href}
      />
    </div>
  );
}
