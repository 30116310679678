import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { InformationIcon } from "components/icons";
import { uploadToS3 } from "libs/uploads";

const variants = {
  light: {
    fill: "bg-white",
  },
  dark: {
    fill: "bg-primary-500",
  },
};

export default function ImageDropzone({
  variant = "dark",
  transactionId,
  setUploadingImages,
  addImages,
  maxImages,
  helpText,
  hasValidationErrors = false,
}) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length <= 0) {
        return;
      }
      setUploadingImages(acceptedFiles);

      Promise.allSettled(
        acceptedFiles.map((file) => uploadToS3(transactionId, file))
      ).then((results) => {
        const converted = results
          .filter((result) => result.status === "fulfilled")
          .map((result) => result.value);
        setUploadingImages([]);

        addImages(converted);
      });
    },
    [addImages, setUploadingImages, transactionId]
  );

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    maxFiles: maxImages,
    onDrop,
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li className="mb-2" key={file.path}>
      {file.path}
      <ul className="pl-4 list-disc">
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));
  const hasFileRejections = fileRejectionItems.length > 0;

  const className = `${
    variants[variant].fill
  } cursor-pointer rounded-lg p-4 mb-4 ${
    hasValidationErrors ? "border border-error-500" : ""
  }`;

  return (
    <section className="container">
      <div {...getRootProps({ className })}>
        <input {...getInputProps()} />
        <p className="uppercase font-bold text-center">
          {helpText ? helpText : `Upload images (${maxImages} max)`}
        </p>
      </div>
      {hasFileRejections && (
        <aside>
          <div className="bg-warning-50 text-black border border-warning-500 rounded p-2 text-sm">
            <div className="flex justify-items-center mb-2">
              <span className="pr-2">
                <InformationIcon />
              </span>
              <h4>Some files were rejected</h4>
            </div>
            <div>
              <ul>{fileRejectionItems}</ul>
            </div>
          </div>
        </aside>
      )}
    </section>
  );
}
