import React, { createContext, useEffect, useState } from "react";
import { head, tail } from "lodash";

let nextMessageId = 0;

export const AppContext = createContext({
  messages: [],
});

const alertIconFromType = (type) => {
  switch (type) {
    case "error":
    case "warning":
      return "error";
    case "success":
      return "tick";
    default:
      return null;
  }
};

export const AppProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  const dismissMessage = (id) =>
    setMessages(messages.filter((m) => m.id !== id));

  const clearMessages = () => setMessages([]);

  const addMessage = (type, title, body = "") =>
    setMessages([
      ...messages,
      {
        id: nextMessageId++,
        variant: type,
        icon: alertIconFromType(type),
        title,
        body,
      },
    ]);

  const fadeOutHead = () => {
    const h = head(messages);
    setMessages([{ ...h, fadeOut: true }, ...tail(messages)]);

    setTimeout(() => {
      dismissMessage(h.id);
    }, 500);
  };

  useEffect(() => {
    if (messages.length > 1 && !messages[0].fadeOut) {
      fadeOutHead();
    }
  }, [messages.length]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let timer;
    if (messages.length === 1) {
      timer = setTimeout(() => {
        fadeOutHead();
      }, 10000);
    }
    return () => clearTimeout(timer);
  }, [messages.length]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppContext.Provider
      value={{
        messages,
        dismissMessage,
        clearMessages,
        addMessage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
