import "./src/styles/global.css";
import axios from "axios";
import React from "react";
import { Amplify, Auth } from "aws-amplify";
import { AppProvider } from "./src/contexts/AppContext";
import { AuthProvider } from "./src/contexts/AuthContext";
import { BasketProvider } from "./src/contexts/BasketContext";
import { AnnouncementsProvider } from "./src/contexts/AnnouncementsContext";
import { LocaleProvider } from "./src/contexts/LocaleContext";
import { RegistrationsProvider } from "./src/contexts/RegistrationsContext";
import { PremierClubProvider } from "./src/contexts/PremierClubContext";

// Set global Axios Platform header for Installer Connect.
axios.defaults.headers.common["Platform"] = "InstallerConnect";

const canonicalHost = process.env.SITE_PRIMARY_HOST;
const canonicalRedirectEnabled =
  canonicalHost !== undefined &&
  canonicalHost !== "" &&
  canonicalHost !== false;

const getJwtToken = async () => ({
  Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
});

const onInitialClientRender = () => {
  if (canonicalRedirectEnabled && window.location.host !== canonicalHost) {
    window.location.host = canonicalHost;
  }

  Amplify.configure({
    aws_project_region: "eu-west-2",
    aws_appsync_graphqlEndpoint: process.env.GATSBY_AWS_APPSYNC_URL,
    aws_appsync_region: "eu-west-2",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_cognito_region: "eu-west-2",
    aws_user_pools_id: process.env.GATSBY_AWS_USER_POOL_ID,
    aws_user_pools_web_client_id: process.env.GATSBY_AWS_WEB_CLIENT_ID,
    aws_user_files_s3_bucket_region: "eu-west-2",
    aws_user_files_s3_bucket: process.env.GATSBY_AWS_UPLOAD_BUCKET_NAME,

    // https://stackoverflow.com/questions/57127211/appsync-resolver-no-email-in-claim
    graphql_headers: getJwtToken,

    Auth: {
      region: "eu-west-2",
      userPoolId: process.env.GATSBY_AWS_USER_POOL_ID,
      userPoolWebClientId: process.env.GATSBY_AWS_WEB_CLIENT_ID,
    },

    API: {
      endpoints: [
        {
          name: "UnauthenticatedAPI",
          endpoint: process.env.GATSBY_AWS_API_GATEWAY_URL,
        },
        {
          name: "AuthenticatedAPI",
          endpoint: process.env.GATSBY_AWS_AUTHENTICATED_API_GATEWAY_URL,
        },
      ],
    },

    federationTarget: "COGNITO_USER_POOLS",
  });
};

const wrapRootElement = ({ element }) => (
  <LocaleProvider>
    <AppProvider>
      <AuthProvider>
        <PremierClubProvider>
          <AnnouncementsProvider>
            <BasketProvider>
              <RegistrationsProvider>{element}</RegistrationsProvider>
            </BasketProvider>
          </AnnouncementsProvider>
        </PremierClubProvider>
      </AuthProvider>
    </AppProvider>
  </LocaleProvider>
);

const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location && location.state && location.state.pageYOffset) {
    window.scrollTo(0, location.state.pageYOffset);

    return false;
  }
};

export { onInitialClientRender, shouldUpdateScroll, wrapRootElement };
