import React from "react";
import { BrowserMultiFormatReader } from "@zxing/library";
import Webcam from "react-webcam";
import { Close } from "../icons";

const BarcodeScanner = ({
  onUpdate,
  onError,
  onClose,
  width = "100%",
  height = "100%",
  facingMode = "environment",
  torch,
  delay = 500,
  videoConstraints,
}) => {
  const webcamRef = React.useRef(null);

  const capture = React.useCallback(() => {
    const codeReader = new BrowserMultiFormatReader();
    const imageSrc = webcamRef?.current?.getScreenshot();
    if (imageSrc) {
      codeReader
        .decodeFromImage(undefined, imageSrc)
        .then((result) => {
          onUpdate(null, result);
        })
        .catch((err) => {
          onUpdate(err);
        });
    }
  }, [onUpdate]);

  React.useEffect(() => {
    // Turn on the flashlight if prop is defined and device has the capability
    if (
      typeof torch === "boolean" &&
      navigator?.mediaDevices?.getSupportedConstraints().torch
    ) {
      const stream = webcamRef?.current?.video.srcObject;
      const track = stream?.getVideoTracks()[0]; // get the active track of the stream
      if (
        track &&
        track.getCapabilities().torch &&
        !track.getConstraints().torch
      ) {
        track
          .applyConstraints({
            advanced: [{ torch }],
          })
          .catch((err) => onUpdate(err));
      }
    }
  }, [torch, onUpdate]);

  React.useEffect(() => {
    const interval = setInterval(capture, delay);
    return () => {
      clearInterval(interval);
    };
  }, [capture, delay]);

  return (
    <div className="relative bg-primary-50 h-80 w-full rounded-md">
      <div
        className="absolute top-2 right-2 cursor-pointer z-10"
        onClick={onClose}
      >
        <Close />
      </div>
      <p className="absolute bottom-2 z-10 w-full text-center text-white">
        Scanning...
      </p>

      <div className="camera-overlay">
        <div className="camera-overlay-element top-left"></div>
        <div className="camera-overlay-element top-right"></div>
        <div className="camera-overlay-element bottom-left"></div>
        <div className="camera-overlay-element bottom-right"></div>
      </div>
      <Webcam
        width={width}
        height={height}
        ref={webcamRef}
        className="rounded-md"
        screenshotFormat="image/jpeg"
        videoConstraints={
          videoConstraints || {
            facingMode,
          }
        }
        audio={false}
        onUserMediaError={onError}
      />
    </div>
  );
};

export default BarcodeScanner;
