import React from "react";

export default function ButtonGroup({
  layout = null,
  fullWidth = false,
  children,
}) {
  return (
    <div className={`flex flex-col ${!fullWidth ? "sm:flex-row" : ""}`}>
      {React.Children.map(children, (child, i) => {
        if (i + 1 === React.Children.count(children)) {
          return child;
        } else {
          return (
            <div className={`mb-8 mr-0 ${!fullWidth ? "sm:mb-0 sm:mr-4" : ""}`}>
              {React.cloneElement(child, {
                className: `w-full ${!fullWidth ? "sm:w-auto" : ""}`,
              })}
            </div>
          );
        }
      })}
    </div>
  );
}
