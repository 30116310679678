import React, { useEffect, useState } from "react";
import { CheckboxChecked, CheckboxUnchecked } from "../../components/icons";
import { useCypressWarning } from "../../hooks";

export default function Checkbox({
  id,
  title,
  onChange,
  error,
  helpText,
  renderTitle,
  value,
  variant = "dark",
  disabled = false,
}) {
  useCypressWarning(id, `id missing for ${title} checkbox component`);

  const [isChecked, setIsChecked] = useState(value ? value : false);

  const toggleCheckedState = () => {
    if (!disabled) {
      if (onChange) {
        onChange(!isChecked);
      }
      setIsChecked(!isChecked);
    }
  };

  const labelTextClass = variant === "light" ? "text-black" : "text-white";

  useEffect(() => {
    setIsChecked(value ? value : false);
  }, [value]);

  return (
    <div>
      <div
        className="flex flex-row items-center"
        role="checkbox"
        aria-checked={isChecked}
        aria-labelledby={id}
      >
        <div
          data-cy={`${id}-checkbox`}
          className={`flex content-center h-5 p-0.5 mt-3 hover:outline rounded outline-1 hover:outline-accent-500 ${
            isChecked
              ? "outline-none"
              : error
              ? "outline outline-error-500"
              : ""
          }`}
          onClick={toggleCheckedState}
        >
          {isChecked ? <CheckboxChecked /> : <CheckboxUnchecked />}
        </div>
        <label
          id={id}
          data-cy={`${id}-label`}
          htmlFor={id}
          onClick={toggleCheckedState}
          className={`text-sm font-normal ${labelTextClass} ml-2 mt-3`}
        >
          {renderTitle ? renderTitle() : title}
        </label>
      </div>
      {!isChecked && helpText && (
        <p className="text-xs text-primary-100 mt-3">{helpText}</p>
      )}
    </div>
  );
}
