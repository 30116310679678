import React from "react";
import { Alert } from "..";

export default function ServerError({ containerClasses, serverErrors }) {
  if (!serverErrors || serverErrors.length === 0) {
    return null;
  }

  return (
    <div className={containerClasses || ""}>
      <Alert variant="error" icon="error" title={serverErrors[0].message} />
    </div>
  );
}
