import React from "react";
import { Link } from "gatsby";
import { useCypressWarning } from "../../hooks";
import { Loading } from "../icons";

const baseContainerStyles = {
  fill: "border drop-shadow drop-shadow-sm active:outline-none focus:outline-none active:drop-shadow-md focus:drop-shadow-md text-center",
  outline:
    "border active:outline-none focus:outline-none bg-transparent text-center",
  none: "border border-transparent bg-transparent text-center",
};

const variants = {
  primary: {
    fill: {
      container:
        "border-primary-500 bg-primary-500 hover:bg-primary-400 active:bg-primary-400 hover:border-primary-400",
      text: "text-white",
    },
    outline: {
      container:
        "border-primary-500 active:bg-primary-100 focus:bg-primary-100 hover:bg-primary-100",
      text: "text-primary-500",
    },
    none: {
      container: "",
      text: "text-primary-500",
    },
  },
  secondary: {
    fill: {
      container:
        "border-accent-500 bg-accent-500 active:bg-accent-400 focus:bg-accent-400 hover:bg-accent-400",
      text: "text-primary-500",
    },
    outline: {
      container:
        "border-accent-500 active:bg-accent-100 focus:bg-accent-100 hover:bg-accent-100",
      text: "text-accent-500",
    },
    none: {
      container: "",
      text: "text-accent-500",
    },
  },
  bronze: {
    fill: {
      container:
        "border-bronze-500 bg-bronze-500 active:bg-bronze-400 focus:bg-bronze-400 hover:bg-bronze-400",
      text: "text-white",
    },
    outline: {
      container:
        "border-bronze-500 active:bg-bronze-100 focus:bg-bronze-100 hover:bg-bronze-100",
      text: "text-bronze-500",
    },
    none: {
      container: "",
      text: "text-bronze-500",
    },
  },
  silver: {
    fill: {
      container:
        "border-silver-500 bg-silver-500 active:bg-silver-400 focus:bg-silver-400 hover:bg-silver-400",
      text: "text-primary-900",
    },
    outline: {
      container:
        "border-silver-500 active:bg-silver-100 focus:bg-silver-100 hover:bg-silver-100",
      text: "text-silver-500",
    },
    none: {
      container: "",
      text: "text-silver-500",
    },
  },
  gold: {
    fill: {
      container:
        "border-gold-500 bg-gold-500 active:bg-gold-400 focus:bg-gold-400 hover:bg-gold-400",
      text: "text-primary-900",
    },
    outline: {
      container:
        "border-gold-500 active:bg-gold-100 focus:bg-gold-100 hover:bg-gold-100",
      text: "text-gold-500",
    },
    none: {
      container: "",
      text: "text-gold-500",
    },
  },
  success: {
    fill: {
      container:
        "border-success-500 bg-success-500 active:bg-success-400 focus:bg-success-400 hover:bg-success-400",
      text: "text-white",
    },
    outline: {
      container:
        "border-success-500 active:bg-success-100 focus:bg-success-100 hover:bg-success-100",
      text: "text-success-500",
    },
    none: {
      container: "",
      text: "text-success-500",
    },
  },
  warning: {
    fill: {
      container:
        "border-warning-500 bg-warning-500 active:bg-warning-400 focus:bg-warning-400 hover:bg-warning-400",
      text: "text-white",
    },
    outline: {
      container:
        "border-warning-500 active:bg-warning-100 focus:bg-warning-100 hover:bg-warning-100",
      text: "text-warning-500",
    },
    none: {
      container: "",
      text: "text-warning-500",
    },
  },
  error: {
    fill: {
      container:
        "border-error-500 bg-error-500 active:bg-error-400 focus:bg-error-400 hover:bg-error-400",
      text: "text-white",
    },
    outline: {
      container:
        "border-error-500 active:bg-error-100 focus:bg-error-100 hover:bg-error-100",
      text: "text-error-500",
    },
    none: {
      container: "",
      text: "text-error-500",
    },
  },
  base: {
    fill: {
      container:
        "border-base-500 bg-base-500 active:bg-base-400 focus:bg-base-400 hover:bg-base-400",
      text: "text-white",
    },
    outline: {
      container:
        "border-base-500 active:bg-base-100 focus:bg-base-100 hover:bg-base-100",
      text: "text-base-500",
    },
    none: {
      container: "",
      text: "text-base-500",
    },
  },
  offWhite: {
    fill: {
      container:
        "border-base-50 bg-base-50 text-primary-500 active:bg-primary-500 focus:bg-primary-500 hover:bg-primary-500 active:text-white focus:text-white hover:text-white",
      text: "",
    },
    outline: {
      container:
        "border-base-50 active:bg-primary-500 focus:bg-primary-500 hover:bg-primary-500",
      text: "text-base-50",
    },
    none: {
      container: "",
      text: "text-white",
    },
  },
  disabled: {
    fill: {
      container: "border-base-200 bg-base-200 opacity-50",
      text: "text-base-300",
    },
    outline: {
      container: "border-base-200 bg-base-100 opacity-50",
      text: "text-base-300",
    },
    none: {
      container: "",
      text: "text-base-300",
    },
  },
};

export default function Button({
  id,
  title,
  fillStyle = "fill",
  variant = "primary",
  fullWidth = false,
  onClick,
  className,
  disabled,
  loading = false,
  type,
  noMagicSubmitPlease = false,
  ...props
}) {
  useCypressWarning(id, `id missing for ${title} button component`);

  const styles = variants[variant][fillStyle];

  const useAnchorTag = props.href ? true : false;

  // TODO: WTF Magic behaviour...
  if (!useAnchorTag && !noMagicSubmitPlease) {
    type = "submit";
  }

  const ButtonLink = useAnchorTag
    ? (props) => {
        // Link begins with a forward slash
        const internal = /^\/(?!\/)/.test(props.href);
        if (internal) {
          return (
              <Link to={props.href} {...props}>
                {props.children}
              </Link>
          );
        }
        return (
            <a href={props.href} {...props}>
              {props.children}
            </a>
        )
      }
      : (props) => <button {...props}>{props.children}</button>;

  const useAnchorTagStyle = `inline-block ${
    disabled ? "pointer-events-none" : "pointer-events-auto"
  }`;

  return (
    <ButtonLink
      type={type}
      data-cy={`${id}-button`}
      disabled={ disabled || loading }
      className={`px-4 py-2 min-w-38 rounded-full ${
        fullWidth ? "w-full" : ""
      } ${
        disabled ? "cursor-not-allowed" : ""
      } ${styles.container} ${baseContainerStyles[fillStyle]} ${
        className || ""
      } ${useAnchorTag && useAnchorTagStyle}`}
      onClick={(e) => {
        if (onClick && !disabled && !loading) {
          onClick(e);
        }

        if (disabled || loading) {
          e.preventDefault();
        }
      }}
      {...props}
    >
      {loading ? (
        <Loading className="mx-auto stroke-white" />
      ) : (
        <span className={`text-sm font-bold ${styles.text}`}>{title}</span>
      )}
    </ButtonLink>
  );
}
