import React from "react";
import { micromark } from "micromark";

export default function Markdown({ content }) {
  return (
    <div
      className="prose prose-sm prose-primary max-w-none"
      dangerouslySetInnerHTML={{
        __html: micromark(content),
      }}
    />
  );
}
