import React, { useState, useContext, useEffect, useCallback } from "react";
import { useForm, useGraphql } from "hooks";
import { get, isEmpty, omit, pick, snakeCase } from "lodash";
import {
  getRegisteredAssetDetailsQuery,
  getBenchmarkInstallForm,
  getProductModelDataQuery,
  getBenchmarkInstallationRecord,
} from "api/queries";
import { validateForm, setMessageHandlers } from "js-laravel-validation";
import { evaluate } from "libs/strings";
import { IsMobile } from "support/helpers";
import validation from "./BenchmarkForm.validation";
import { optionalRules, determineDependantFields } from "./OptionalRules";

const BenchmarkFormContext = React.createContext();

const BENCHMARK_TO_INSTALLER_CONNECT_MAPPING = {
  installation_date: "installationDate",
  customer_email: "customerEmail",
  "property[address_line_1]": ["houseNumber", "street"],
  "property[town_city]": "city",
  "property[postcode]": "postcode",
  serial_number: "serialNumber",
};

const setCustomErrorMessages = () => {
  setMessageHandlers({ required: () => "REQUIRED" });
  setMessageHandlers({ string: () => "STRING" });
  setMessageHandlers({ integer: () => "INTEGER" });
  setMessageHandlers({ digits_between: () => "DIGITS_BETWEEN" });
  setMessageHandlers({ numeric: () => "NUMERIC" });
  setMessageHandlers({ required_if_multiple: () => "REQUIRED_IF_MULTIPLE" });
};

export function BenchmarkFormProvider({ assetId, children }) {
  const form = useForm({ status: 1, is_mobile: IsMobile() });
  const [fieldErrors, setFieldErrors] = useState({});
  const [groupedPickerData, setGroupedPickerSourceData] = useState({});
  const [hasProductModelData, setHasProductModelData] = useState(true);
  const [loadingProductModelData, setLoadingProductModelData] = useState(true);
  const [
    loadingBenchmarkInstallationRecord,
    setLoadingBenchmarkInstallationRecord,
  ] = useState(true);

  const { loading, executeQuery: executeGetRegisteredAssetDetailsQuery } =
    useGraphql(getRegisteredAssetDetailsQuery);

  const {
    loading: loadingInstallForm,
    data: installFormData,
    executeQuery: executeGetInstallFormQuery,
  } = useGraphql(getBenchmarkInstallForm);

  const { executeQuery: executeGetProductModelDataQuery } = useGraphql(
    getProductModelDataQuery
  );

  const { executeQuery: executeGetBenchmarkInstallationRecord } = useGraphql(
    getBenchmarkInstallationRecord
  );

  useEffect(() => {
    executeGetInstallFormQuery();
  }, [executeGetInstallFormQuery]);

  const formStructure = JSON.parse(
    get(installFormData, "getBenchmarkInstallForm.formStructure", "[]")
  );

  const benchmarkKeys = Object.keys(BENCHMARK_TO_INSTALLER_CONNECT_MAPPING);

  const dependantFields = determineDependantFields(formStructure);

  const clearErrorsForDependantFields = (id) => {
    const fields = dependantFields[id] || [];

    setFieldErrors(omit(fieldErrors, [id, ...fields]));
  };

  const getAssetData = useCallback(async () => {
    const assetData = await executeGetRegisteredAssetDetailsQuery({
      assetId: assetId || "",
    });

    return {
      ...get(
        assetData,
        "data.getAssetRegistrationHistoryDetails.assetDetails",
        {}
      ),
    };
  }, [assetId, executeGetRegisteredAssetDetailsQuery]);

  const getBenchmarkProductModelData = useCallback(
    async (serialNumber, assetType) => {
      const { data } = await executeGetProductModelDataQuery({
        serialNumber,
        assetType,
      });

      const productModelData = get(data, "getBenchmarkProductModelData", {});

      if (!isEmpty(productModelData)) {
        Object.entries(productModelData).forEach(([key, val]) => {
          form.setFieldValue(snakeCase(key), val);
        });
      }
      setHasProductModelData(!isEmpty(productModelData));
      setLoadingProductModelData(false);
    },
    [executeGetProductModelDataQuery]
  );

  const getBenchmarkInstallationData = useCallback(async (serialNumber) => {
    const { data } = await executeGetBenchmarkInstallationRecord({
      serialNumber: serialNumber,
    });

    const benchmarkInstallationData = JSON.parse(
      data?.getBenchmarkInstallationRecord
    );

    if (!isEmpty(benchmarkInstallationData)) {
      Object.entries(benchmarkInstallationData).forEach(([key, val]) => {
        form.setFieldValue(key, val);
      });
    }
    setLoadingBenchmarkInstallationRecord(false);
  }, []);

  const getInitialFieldValues = useCallback(async () => {
    const initialValues = await getAssetData();

    const assetType = initialValues.originalBoilerSerialNumber
      ? "Lpg"
      : "Boiler";

    getBenchmarkProductModelData(initialValues.serialNumber, assetType);

    for (const benchmarkKey of benchmarkKeys) {
      if (benchmarkKey === "property[address_line_1]") {
        form.setFieldValue(
          [benchmarkKey],
          get(
            initialValues,
            BENCHMARK_TO_INSTALLER_CONNECT_MAPPING[benchmarkKey][0],
            ""
          ) +
            " " +
            get(
              initialValues,
              BENCHMARK_TO_INSTALLER_CONNECT_MAPPING[benchmarkKey][1],
              ""
            )
        );
      } else {
        form.setFieldValue(
          [benchmarkKey],
          get(
            initialValues,
            BENCHMARK_TO_INSTALLER_CONNECT_MAPPING[benchmarkKey],
            ""
          )
        );
      }
    }

    getBenchmarkInstallationData(initialValues.serialNumber);
  }, [getAssetData]);

  const extractHiddenCalculations = (formStructure) => {
    return formStructure.reduce((acc, item) => {
      if (item.id && item.hidden_calculation) {
        acc[item.id] = item.hidden_calculation;
      }

      if (item.items) {
        const innerCalculations = extractHiddenCalculations(item.items);
        return { ...acc, ...innerCalculations };
      }

      return acc;
    }, {});
  };

  const hiddenCalculations = extractHiddenCalculations(formStructure);

  const getFieldProps = (id) => {
    const internalFieldProps = form.getFieldProps(id);

    return {
      ...internalFieldProps,
      onChange: (value) => {
        internalFieldProps.onChange(value);
        clearError(id);
        clearErrorsForDependantFields(id);

        if (hiddenCalculations[id]) {
          const calculation = hiddenCalculations[id];

          const variables = pick(form.fieldValues, calculation.keys);

          variables[id] = value;

          const result = evaluate(calculation.equation, variables);

          if (result) {
            form.setFieldValue(calculation.id, result.toString());
          } else {
            form.setFieldValue(calculation.id, null);
          }
        }
      },
      error: fieldErrors[id]?.length > 0,
      helpText: fieldErrors[id]?.join(" "),
    };
  };

  const createCustomErrorMessage = (fieldName, errors, formType) => {
    for (const errorMessage of errors.reverse()) {
      switch (errorMessage) {
        case "STRING":
          return `Please enter ${fieldName}.`;
        case "INTEGER":
          return "Please select an option.";
        case "NUMERIC":
          if (formType === 1) {
            return `Please enter ${fieldName}.`;
          }
          return "Please select an option.";
        case "DIGITS_BETWEEN":
          if (formType === 6) {
            return "Please select an option.";
          }
          return "Field must be checked.";
        case "REQUIRED_IF_MULTIPLE":
        case "REQUIRED":
          return "This field is required.";
        default:
          return errorMessage;
      }
    }
  };

  const validateFieldValues = (values, items) => {
    const laravelSchema = validation(items, values);

    for (let index in values) {
      if (laravelSchema.hasOwnProperty(index)) {
        laravelSchema[index] = {
          ...laravelSchema[index],
          value: values[index],
        };
      } else {
        laravelSchema[index] = {
          ...laravelSchema[index],
          name: index,
          validation: "",
          value: values[index],
          form_type: null,
        };
      }
    }

    const { errors } = validateForm({ formData: laravelSchema });
    const optionalRulesErrors = optionalRules(values, items);

    let customErrorMessages = {};

    for (const optRule in optionalRulesErrors) {
      const error = optionalRulesErrors[optRule];

      if (error === true) {
        customErrorMessages[optRule] = ["Please select at least one option."];
      }
    }

    for (const errorField in errors) {
      const error = errors[errorField];
      const formType = laravelSchema[errorField].form_type;

      const message = createCustomErrorMessage(
        errorField.split("_").join(" "),
        error,
        formType
      );

      customErrorMessages[errorField] = [message];
    }

    setFieldErrors(customErrorMessages);

    return customErrorMessages;
  };

  useEffect(() => {
    getInitialFieldValues();
  }, [assetId, getInitialFieldValues]);

  useEffect(() => {
    setCustomErrorMessages();
  }, []);

  const clearError = (id) => {
    setFieldErrors(omit(fieldErrors, [id]));
  };

  const setGroupedPickerData = useCallback((val) => {
    setGroupedPickerSourceData((sourceData) => ({
      ...sourceData,
      ...val,
    }));
  });

  let providerValue = {
    ...form,
    noneditableFields: [...benchmarkKeys, "address_1"],
    loadingBenchmark:
      loading ||
      loadingInstallForm ||
      loadingProductModelData ||
      loadingBenchmarkInstallationRecord,
  };

  return (
    <BenchmarkFormContext.Provider
      value={{
        ...providerValue,
        groupedPickerData,
        setGroupedPickerData,
        getFieldProps,
        validateFieldValues,
        clearError,
        formStructure,
        hasProductModelData,
      }}
    >
      {children}
    </BenchmarkFormContext.Provider>
  );
}

export function useBenchmarkForm() {
  return useContext(BenchmarkFormContext);
}
