import React from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularProgressBar = ({ percentage }) => {
  return (
    <div style={{ width: 170, height: 170 }}>
      <CircularProgressbarWithChildren
        value={percentage}
        styles={{
          path: {
            stroke: "white",
            strokeLinecap: "rounded",
            transformOrigin: "center center",
          },
          trail: {
            stroke: "#8b868f",
            strokeLinecap: "rounded",
            transformOrigin: "center center",
          },
          text: {
            fill: "white",
            fontSize: "12px",
          },
        }}
      >
        <p className="text-base">{percentage}%</p>
        <p className="text-base">complete</p>
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default CircularProgressBar;
