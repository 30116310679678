import React, { createContext, useState } from "react";
import { IntlProvider } from "react-intl";
import {
  determineLocaleFromCountry,
  LOCALE_CA,
  LOCALE_IE,
  LOCALE_UK,
  LOCALE_US,
} from "support/constants";
import { useAuth } from "../hooks";
import ukMessages from "../lang/en-GB.json";
import ieMessages from "../lang/en-IE.json";
import usCanadaMessages from "../lang/en-US.json";

export const LocaleContext = createContext({
  locale: LOCALE_UK,
  setLocale: () => {},
});

const localeMessages = {
  [LOCALE_UK]: ukMessages,
  [LOCALE_IE]: ieMessages,
  [LOCALE_US]: usCanadaMessages,
  [LOCALE_CA]: usCanadaMessages,
};

function determineDefaultLocale(user) {
  if (user && user.countryCode) {
    return determineLocaleFromCountry(user.countryCode);
  }

  const browserLocale =
    typeof navigator === "object" ? navigator.language : null;
  if ([LOCALE_CA, LOCALE_IE, LOCALE_UK, LOCALE_US].includes(browserLocale)) {
    return browserLocale;
  }
  return LOCALE_UK;
}

export const LocaleProvider = ({ children }) => {
  const { user } = useAuth();

  const defaultLocale = determineDefaultLocale(user);

  const [locale, setLocale] = useState(defaultLocale);

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <IntlProvider
        messages={localeMessages[locale]}
        locale={locale}
        defaultLocale={defaultLocale}
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};
