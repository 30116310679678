import React, { useEffect, useState } from "react";
import { conditionalClass } from "support/helpers";
import { Back, EllipsisCircleIcon } from "../icons";

const ProgressIndicator = ({
  currentStep,
  totalSteps,
  showBack = true,
  onBack,
  onClose,
  hideProgress = false,
}) => {
  const [currentProgress, setCurrentProgress] = useState(0);

  useEffect(() => {
    if (totalSteps > 0) {
      setCurrentProgress(
        Math.min(100, Math.trunc(((currentStep + 1) / totalSteps) * 100))
      );
    }
  }, [currentStep, totalSteps]);

  return (
    <div className="bg-primary-400 px-8">
      <div className="container flex mx-auto py-4 text-white">
        <span
          data-cy="progress-indicator-back"
          className={`mr-auto cursor-pointer ${conditionalClass(
            !showBack,
            "invisible"
          )}`}
          onClick={() => {
            if (onBack) {
              onBack();
            }
          }}
        >
          <Back />
        </span>
        {!hideProgress && (
          <>
            <span className="font-bold items-center">{currentProgress}%</span>
            &nbsp;Progress
          </>
        )}
        <span
          data-cy="progress-indicator-close"
          className="ml-auto cursor-pointer"
          onClick={onClose}
        >
          <p className="hidden sm:flex -ml-12">Save / Exit</p>
          <EllipsisCircleIcon className="w-6 h-6 sm:hidden" />
        </span>
      </div>
    </div>
  );
};

export default ProgressIndicator;
