import React, { useState } from "react";

const Thumbnail = ({ src, altText, fallback }) => {
  const [useFallback, setUseFallback] = useState(false);

  return (
    <div className="aspect-1 rounded-md overflow-hidden">
      {useFallback ? (
        fallback
      ) : (
        <img
          src={src}
          alt={altText}
          onError={() => {
            if (fallback) {
              setUseFallback(true);
            }
          }}
        />
      )}
    </div>
  );
};

export default Thumbnail;
