import React, { useState } from "react";
import { PickerArrowDown, ArrowUp } from "../icons";

export default function Accordion({ title, content }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="bg-primary-400 rounded-lg text-white mb-4 p-4">
      <div
        className={`flex justify-between items-center ${isActive && "mb-4"}`}
        onClick={() => setIsActive(!isActive)}
      >
        <p>{title}</p>
        <div>
          {isActive ? <ArrowUp /> : <PickerArrowDown colour="#FFFFFF" />}
        </div>
      </div>

      {isActive && (
        <div>
          <div className="border border-primary-200" />
          <div className="mt-4">{content}</div>
        </div>
      )}
    </div>
  );
}
