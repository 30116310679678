import { isEmpty, has, get } from "lodash";

const GTM_DEBUG = process.env.GATSBY_GTM_DEBUG === 'true';

export const dataLayerPush = (value) => {
  try {
    if (GTM_DEBUG) {
      console.log("dataLayerPush:");
      console.table(value);
    } else {
      window.dataLayer?.push(value);
    }
  } catch (error) {
    console.error("Error with data layer push", error);
  }
};

export const gaHandleSessionLoad = (user) => {
  try {
    if (isEmpty(user)) {
      dataLayerPush({ UserType: "visitor", userId: undefined, userBand: undefined });
    } else if (!user.isApproved) {
      dataLayerPush({ UserType: "registered", userId: user.cognitoId, userBand: undefined });
    } else {
      dataLayerPush({ UserType: "approved", userId: user.cognitoId, userBand: user.band });
    }

    dataLayerPush({ event: "user_type_change" });
  } catch (error) {
    console.error("Error with GA session load event", error);
  }
};

export const gaHandleRegistration = (registration, result) => {
  const buildBasicEvent = (reg) => {
    return {
      asset_sap_number: registration.assetInfo.sapNumber,
      asset_model_name: registration.assetInfo.modelName,
      asset_install_date: registration.installationDetails.installationDate,
      asset_customer_known: registration.customerDetails.customerDetailsKnown,
      asset_merchant: registration.merchantDetails.merchant,
    };
  }

  try {

    dataLayerPush({ registration: null });

    switch (registration.submissionAssetType) {
      case "boiler":
        dataLayerPush({
          registration: {
            ...buildBasicEvent(registration),
            with_gas_safe: registration.gasSafe,
            with_lpg: registration.lpgInfo !== null,
            with_control: has(registration, "controlAssetInfo"),
            with_cylinder: has(registration, "cylinderAssetInfo"),
            with_filter: has(registration, "filterAssetInfo"),
          }
        });
        dataLayerPush({ event: "boiler_registration" });
        break;
      case "cylinder":
        dataLayerPush({
          registration: {
            ...buildBasicEvent(registration),
          }
        });
        dataLayerPush({ event: "cylinder_registration" });
        break;
      case "control":
        dataLayerPush({
          registration: {
            ...buildBasicEvent(registration),
          }
        });
        dataLayerPush({ event: "control_registration" });
        break;
      default:
        console.warn(`Registration type ${registration.submissionAssetType} not recognised`);
        break;
    }

    const pointsEarned = get(result, "data.submitProductRegistration.spentPoints", 0);
    
    if (pointsEarned > 0) {
      dataLayerPush({ event: "earn_virtual_currency", virtual_currency_name: "points", value: pointsEarned });
    }
  } catch (error) {
    console.error("Error with GA registration event", error);
  }
};