import * as yup from "yup";
import {
  postcodeRegExp,
  irishPostcodeRegExp,
  usPostcodeRegExp,
  canadaPostcodeRegExp,
} from "support/constants";

const postcodeSchema = (countryCode) => {
  switch (countryCode) {
    case "US":
      return {
        postcode: yup
          .string()
          .required("Please enter a ZIP code")
          .matches(usPostcodeRegExp, "Please enter a valid ZIP code"),
      };
    case "CA":
      return {
        postcode: yup
          .string()
          .required("Please enter a postal code")
          .matches(canadaPostcodeRegExp, "Please enter a valid postal code"),
      };
    case "IE":
      return {
        postcode: yup.string().matches(irishPostcodeRegExp, {
          message: "Please enter a valid eirecode",
          excludeEmptyString: true,
        }),
      };
    default:
      return {
        postcode: yup
          .string()
          .required("Please enter a postcode")
          .matches(postcodeRegExp, "Please enter a valid postcode"),
      };
  }
};

export const schema = (countryCode) => {
  return yup.object().shape(postcodeSchema(countryCode));
};
