import React from "react";
import { useCypressWarning } from "hooks";
import { Picker } from "components";
import { get } from "lodash";
import { useBenchmarkForm } from "../../views/Benchmark/BenchmarkFormContext";

const RenderGroupedPickers = ({
  id,
  title,
  source,
  fieldValues,
  getFieldProps,
  setFieldValue,
  groupedPickerData,
  dropdownBrandFor,
}) => {
  const filterKey = get(source, "filter.key");
  const filterValue = get(source, "filter.value");

  const formattedData = source.filter
    ? groupedPickerData[source.key]
        .filter((x) => x[filterKey] === fieldValues[filterValue])
        .map((x) => ({
          title: x.name,
          value: x.id,
        }))
    : groupedPickerData[source.key].map((x) => ({
        title: x.name,
        value: x.id,
      }));

  const fieldProps = getFieldProps(id);

  return (
    <div className="mb-6">
      <Picker
        id={id}
        title={title}
        options={formattedData}
        disabled={formattedData === undefined || formattedData.length === 0}
        {...fieldProps}
        onChange={(val) => {
          if (dropdownBrandFor && val !== fieldValues[id]) {
            setFieldValue(dropdownBrandFor, "");
          }
          fieldProps.onChange(val);
        }}
      />
    </div>
  );
};

export default function GroupedPickers({
  id,
  title,
  dropdowns,
  value,
  disabled = false,
  onChange,
  ...props
}) {
  useCypressWarning(id, `id missing for ${title} picker component`);

  const { fieldValues, getFieldProps, setFieldValue, groupedPickerData } =
    useBenchmarkForm();

  return (
    <div className="mt-8 mb-6">
      <p className="text-white text-sm font-normal mb-4">{title}</p>

      {dropdowns.map((dropdown) => {
        return (
          <RenderGroupedPickers
            id={dropdown.id}
            title={dropdown.title}
            source={dropdown.source}
            dropdownBrandFor={dropdown.brand_for}
            fieldValues={fieldValues}
            getFieldProps={getFieldProps}
            setFieldValue={setFieldValue}
            groupedPickerData={groupedPickerData}
          />
        );
      })}
    </div>
  );
}
