import React, { useCallback, useEffect, useState } from "react";
import { Button, Picker } from "components";
import { useApp, useForm } from "hooks";

import { schema as validationSchema } from "./AddressLookup.validation";
import { API } from "aws-amplify";

function getAddressPlaceholder(addressOptions) {
  if (addressOptions === undefined) {
    return "Search a postcode to find addresses";
  } else if (addressOptions.length === 0) {
    return "No addresses found";
  } else if (addressOptions.length > 0) {
    return `${addressOptions.length} addresses found`;
  }

  return "";
}

export default function AddressFinder({
  postcode = "",
  disableComponent = false,
  isSearching,
  setIsSearching = () => {},
  pickerDefault = false,
  setPickerDefault = () => {},
  onChange = () => {},
  onCheckPostcode = async () => {},
  country = "GB",
}) {
  const { addMessage } = useApp();

  const [addressOptions, setAddressOptions] = useState(undefined);

  const { fieldValues, getFieldProps, setFieldValue } = useForm(
    {
      postcode,
      houseNumber: "",
      street: "",
      city: "",
    },
    validationSchema
  );

  const handleSearch = useCallback(async () => {
    setFieldValue("postcode", postcode.trim());

    const isValid = await onCheckPostcode(postcode);

    if (!isValid) {
      setAddressOptions(undefined);
      return;
    }

    setIsSearching(true);

    try {
      const result = await API.get(
        "UnauthenticatedAPI",
        `/addresses/${country}/${postcode}`
      );

      setAddressOptions(
        result.data.map((x) => ({
          title: x.fullAddress,
          value: JSON.stringify(x),
        }))
      );

      setPickerDefault(false);
    } catch (error) {
      console.log(error);
      addMessage(
        "error",
        "An error occurred when searching for addresses. Please try again."
      );
    }

    setIsSearching(false);
  }, [
    setFieldValue,
    postcode,
    onCheckPostcode,
    setIsSearching,
    country,
    setPickerDefault,
    addMessage,
  ]);

  useEffect(() => {
    if (
      onChange &&
      getFieldProps("postcode").value !== "" &&
      addressOptions !== undefined
    ) {
      onChange(fieldValues);
    }
  }, [fieldValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div>
        <Picker
          variant="light"
          id="address"
          title="Select Address *"
          disabled={
            addressOptions === undefined ||
            addressOptions.length === 0 ||
            disableComponent
          }
          placeholder={getAddressPlaceholder(addressOptions)}
          defaultValue={pickerDefault && getAddressPlaceholder(undefined)}
          options={addressOptions}
          optionsReset={true}
          onChange={(val) => {
            const address = JSON.parse(val);

            const houseNumber = [
              address.subBuildingName.trim(),
              address.buildingNumber.trim() || address.buildingName,
            ]
              .filter((s) => !!s)
              .join(", ");

            setFieldValue("houseNumber", houseNumber);
            setFieldValue("street", address.thoroughfare);
            setFieldValue("postcode", address.postcode);
            setFieldValue("city", address.town);
          }}
        />
        <div className="mt-4">
          <Button
            type="button"
            id="search"
            title="Search"
            variant={isSearching ? "disabled" : "primary"}
            disabled={isSearching || disableComponent}
            loading={isSearching}
            fillStyle={isSearching ? "fill" : "outline"}
            onClick={handleSearch}
          />
        </div>
      </div>
    </>
  );
}
