import React, { useEffect, useState } from "react";
import Button from "../Button/Button";
import { ArrowLeft, ArrowRight } from "../icons";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";

import { GatsbyImage } from "gatsby-plugin-image";

import "swiper/css";
import "swiper/css/pagination";
import { useLocale } from "hooks";
import { LOCALE_UK } from "support/constants";

export default function Carousel({
  slideContent,
  speed,
  loop,
  autoplayDelay,
  autoPlay = true,
  controlColor = "#FFFFFF",
  showJoinButton = true,
  centerAlignImageSlide = true,
}) {
  const [showing, setShowing] = useState(false);
  const swiper = useSwiper();
  SwiperCore.use([Autoplay]);

  useEffect(() => {
    setShowing(false);

    setTimeout(() => setShowing(true));
  });

  const SlidePrevButton = ({ controlColor }) => {
    const swiper = useSwiper();
    return (
      <div
        className="mx-0 cursor-pointer sm:mx-4 carousel-button-prev select-none"
        onClick={() => swiper.slidePrev()}
      >
        <ArrowLeft size={24} colour={controlColor} />
      </div>
    );
  };
  const SlideNextButton = ({ controlColor }) => {
    const swiper = useSwiper();
    return (
      <div
        className="mx-0 cursor-pointer sm:mx-4 carousel-button-next select-none"
        onClick={() => swiper.slideNext()}
      >
        <ArrowRight size={24} colour={controlColor} />
      </div>
    );
  };

  const ImageSlide = ({ index, title, imageUrl, linkText, url }) => {
    return (
      <SwiperSlide>
        <div
          className={`flex items-center w-full pt-6 pb-10 text-white md:pb-6 md:pt-0 ${
            centerAlignImageSlide
              ? "justify-center px-8 sm:px-16"
              : "sm:justify-center lg:justify-start"
          }`}
        >
          <div
            className={`flex flex-col items-center md:flex-row ${
              centerAlignImageSlide
                ? "justify-center"
                : "sm:justify-center lg:justify-start"
            }`}
          >
            {(title || showJoinButton) && (
              <div className="flex-col order-2 md:order-1 sm:w-full md:w-1/3 md:mr-8">
                <p className="text-xl lg:text-3xl font-semibold text-white md:font-bold">
                  {title}
                </p>
                {showJoinButton && (
                  <Button
                    id={`join-connect-${index}`}
                    fillStyle="outline"
                    variant="offWhite"
                    title={linkText || "Join Connect"}
                    href={url}
                    target="_blank"
                    className="mt-5"
                  />
                )}
              </div>
            )}
            {typeof imageUrl == "object" && (
              <GatsbyImage
                image={imageUrl.gatsbyImageData}
                alt={imageUrl.title ?? ""}
                placeholder="none"
                loading="none"
              />
            )}
            {typeof imageUrl == "string" && (
              <img
                className="order-1 md:order-2 md:max-w-2/3 md:ml-8"
                alt={title}
                src={imageUrl}
              />
            )}
          </div>
        </div>
      </SwiperSlide>
    );
  };

  const PointsSlide = ({
    _index,
    title,
    imageUrl,
    imageDescription,
    bronze,
    silver,
    gold,
  }) => {
    const { locale } = useLocale();

    const points = [
      {
        title: "Bronze",
        className: `bg-no-repeat bg-left-top bg-[url('../images/bronze-ticket-large.svg')] pl-14`,
        points: bronze,
      },
    ];

    if (locale === LOCALE_UK) {
      points.push(
        {
          title: "Silver",
          className: `bg-no-repeat bg-left-top bg-[url('../images/silver-ticket-large.svg')] pl-14`,
          points: silver,
        },
        {
          title: "Gold",
          className: `bg-no-repeat bg-left-top bg-[url('../images/golden-ticket-large.svg')] pl-14`,
          points: gold,
        }
      );
    }

    return (
      <SwiperSlide>
        <div className="flex items-center justify-center w-full px-8 pt-6 pb-10 text-white sm:px-16 md:pb-6 md:pt-0">
          <div className="flex flex-col items-center justify-center md:flex-row">
            <div className="flex-col order-2 text-xl font-bold sm:w-full md:w-2/3 md:mr-8 text-primary-500">
              <p>{title}</p>
              <dl className="space-y-2">
                {points.map((point) => (
                  <>
                    <dt className="sr-only">{point.title}</dt>
                    <dd className={point.className}>{point.points}</dd>
                  </>
                ))}
              </dl>
            </div>
            <img
              className="order-1 md:max-w-1/3 md:ml-8"
              src={imageUrl}
              alt={imageDescription}
            />
          </div>
        </div>
      </SwiperSlide>
    );
  };

  const isPointsSlide = (slideType) => {
    return slideType === "ContentfulCarouselPointsSlide";
  };

  const slides = Array.from({ length: slideContent?.length }).map(
    (_, index) => {
      const slide = slideContent.find(({ id }) => index === id);

      if (isPointsSlide(slide.type)) {
        return (
          <PointsSlide
            index={index}
            title={slide.title}
            imageUrl={slide.url}
            availability={slide.availability}
            imageDescription={slide.imageDescription}
            bronze={slide.bronze}
            silver={slide.silver}
            gold={slide.gold}
          />
        );
      } else {
        return (
          <ImageSlide
            index={index}
            title={slide.title}
            imageUrl={slide.url}
            linkText={slide.buttonText}
            url={slide.link}
            availability={slide.availability}
          />
        );
      }
    }
  );

  const hasMultipleSlides = (slides) => {
    return slides && slides.length > 1;
  };

  const autoPlayConfig = (slides, autoPlay, autoplayDelay) => {
    if (hasMultipleSlides(slides) && autoPlay) {
      return { delay: autoplayDelay, disableOnInteraction: false };
    } else {
      return hasMultipleSlides(slides) && autoPlay;
    }
  };

  if (!showing) {
    return null;
  }

  return (
    <Swiper
      pagination
      modules={[Pagination]}
      speed={speed}
      loop={loop}
      autoplay={autoPlayConfig(slides, autoPlay, autoplayDelay)}
      allowTouchMove={hasMultipleSlides(slides)}
    >
      {hasMultipleSlides(slideContent) && (
        <SlidePrevButton controlColor={controlColor} />
      )}
      {slides.map((slideContent, index) => (
        <SwiperSlide key={`slide-${index}`} virtualIndex={index}>
          {slideContent}
        </SwiperSlide>
      ))}
      {hasMultipleSlides(slideContent) && (
        <SlideNextButton controlColor={controlColor} />
      )}
    </Swiper>
  );
}
