import React, { createContext, useCallback, useEffect, useState } from "react";
import { getFailedRegistrationsQuery } from "../api/queries";
import { useAuth, useGraphql } from "../hooks";

export const RegistrationsContext = createContext({
  failedRegistrations: [],
});

export const RegistrationsProvider = ({ children }) => {
  const { user } = useAuth();
  const [failedRegistrations, setFailedRegistrations] = useState([]);

  const { executeQuery: executeGetFailedRegistrationsQuery } = useGraphql(
    getFailedRegistrationsQuery
  );

  const loadFailedRegistrations = useCallback(async () => {
    if (!user.isApproved) {
      return;
    }
    const { data } = await executeGetFailedRegistrationsQuery();

    if (data !== null) setFailedRegistrations(data.getFailedRegistrations);
  }, [executeGetFailedRegistrationsQuery, user]);

  useEffect(() => {
    loadFailedRegistrations();
  }, [loadFailedRegistrations]);

  return (
    <RegistrationsContext.Provider
      value={{ failedRegistrations, loadFailedRegistrations }}
    >
      {children}
    </RegistrationsContext.Provider>
  );
};
