import React from "react";

export default function BoilerDetails({ imageUrl, model }) {
  return (
    <div className="flex flex-col mb-8 items-center">
      {imageUrl !== null && (
        <img
          className="max-w-xs max-h-64 mb-8 sm:mb-0 mr-0 sm:mr-4 object-contain"
          src={`${imageUrl}`}
          alt="The boiler"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src =
              "https://stgaukcdn01.blob.core.windows.net/installerconnect/assets/no-image.png";
          }}
        />
      )}

      <div>
        <h1 className="text-white font-bold text-xl mt-6">{model}</h1>
      </div>
    </div>
  );
}
