import React, { useMemo } from "react";
import { Link } from "gatsby";
import { Email, Phone } from "../../components/icons";
import { useAuth, useWorkingHours, useLocale } from "hooks";
import { isNorthAmericanLocale } from "support/helpers";

export default function Footer({ contactNumber, contactEmail }) {
  const { isLoggedIn } = useAuth();
  const isWorkingHours = useWorkingHours();
  const contactLink = useMemo(
    () => (isLoggedIn ? "/app/contact" : "/contact"),
    [isLoggedIn]
  );

  const { locale } = useLocale();

  const showContactNumber = isWorkingHours && !isNorthAmericanLocale(locale); 

  return (
    <div className="flex flex-wrap w-full px-8 py-4 bg-primary-500">
      <div className="container flex flex-row flex-wrap justify-between mx-auto">
        <Link to={contactLink} className="text-white">
          <span className="hidden sm:inline-block"> Having trouble?</span>{" "}
          Contact us.
        </Link>
        <nav className="flex justify-center order-last w-full pt-4 text-white lg:order-none lg:w-auto lg:pt-0">
          <ul className="flex text-xs divide-x divide-solid md:text-base center">
            <li className="px-4">
              <Link to="/site-map">Site map</Link>
            </li>
            <li className="px-4">
              <Link to="/terms-and-conditions">Terms and conditions</Link>
            </li>
            <li className="px-4">
              <Link to="/privacy-policy">Privacy policy</Link>
            </li>
            <li className="px-4">
              <Link to="/cookie-policy">Cookie policy</Link>
            </li>
          </ul>
        </nav>
        <div>
          <div className="flex flex-row items-center">
            {showContactNumber ? (
              <>
                <Phone className="mr-4" />
                <p className="text-sm font-bold text-white mr-4">
                  <a href={`tel:${contactNumber}`}>{contactNumber}</a>
                </p>
              </>
            ) : (
              <>
                <Email className="mr-4" fill="#fff" />
                <p className="text-sm font-bold text-white mr-4">
                  <a href={`mailto:${contactEmail}`}>
                    {contactEmail.substring(17).toLowerCase()}
                  </a>
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
