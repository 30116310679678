import React from "react";

export default function Tooltip({ message, children }) {
  return (
    <div className="relative flex flex-col items-center group">
      {children}
      <div className="absolute top-0 right-0 flex flex-col items-end mt-8 hidden group-hover:flex min-w-[216px]">
        <div className="relative z-20 w-3 h-3 mr-1.5 rotate-45 border-t border-l border-primary-200 bg-white"></div>
        <span className="-mt-1.5 z-10 p-2 text-xs bg-white shadow-lg border border-primary-200 rounded-md">
          {message}
        </span>
      </div>
    </div>
  );
}
