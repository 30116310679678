import React from "react";
import { Close } from "../icons";
import OutsideClickHandler from "react-outside-click-handler";

const Modal = ({ onClose, showClose = false, children }) => {
  return (
    <section className="z-50 fixed left-0 top-0 right-0 bottom-0 flex items-center bg-black bg-opacity-50">
      <OutsideClickHandler onOutsideClick={onClose} display="contents">
          {showClose && (
            <Close
              className="cursor-pointer absolute top-0 right-0 z-10"
              fill="black"
              onClick={onClose}
            />
          )}
          {children}
      </OutsideClickHandler>
    </section>

  );
};

export default Modal;
