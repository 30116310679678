import { isWithinInterval, isWeekend } from "date-fns";
import { useEffect, useState } from "react";

const checkTime = () => {
    let now = new Date();
    let am = new Date().setHours(9, 0, 0, 0);
    let pm = new Date().setHours(17, 0, 0, 0);
    return isWeekend(now) ? false : isWithinInterval(now, { start: am, end: pm })
}

export default function useWorkingHours() {

    const [isWorkingHours, setIsWorkingHours] = useState(checkTime);

    useEffect(() => {
        const intervalId = setInterval(() => {
          setIsWorkingHours(checkTime);
        }, 60000);
        return () => {
          clearInterval(intervalId);
        };
      }, []);

    return isWorkingHours

}