import React from "react";
import { useCypressWarning } from "../../hooks";
import { Loading } from "../icons";
import { countryCodeOptions } from "support/constants";

export default function CountryButton({
  id,
  country = "GB",
  onClick,
  selected = false,
  disabled = false,
  loading = false,
}) {
  useCypressWarning(id, `id missing for ${country} button component`);

  const variant = countryCodeOptions.find((c) => c.value === country);

  return (
    <button
      className={`border-2 hover:border-accent-500 rounded-md bg-primary-200 ${
        selected ? `border-accent-500` : `border-primary-700`
      }`}
      onClick={onClick}
      disabled={disabled}
      type="button"
    >
      {loading ? (
        <Loading className="mx-auto stroke-white" />
      ) : (
        <div className="px-4 pt-4 md:px-6 md:pt-6">
          <img src={variant.image} alt={variant.title} />
          <div className="h-20 text-center py-3 md:py-3 flex">
            <span className="uppercase font-bold text-primary-600 flex-1 my-auto">
              {variant.title}
            </span>
          </div>
        </div>
      )}
    </button>
  );
}
