import { Link } from "gatsby";
import React from "react";
import Button from "../Button/Button";

export default function PromotionalContentItem({
  id,
  imageUrl,
  title,
  buttonTitle,
  url,
  target,
  children
}) {
  return (
    <Link
      to={url}
      target={target}
      data-cy={`${id}-promotional-content-item`}
      className="w-full flex flex-col justify-between"
    >
      <div>
        {imageUrl && (
          <img src={imageUrl} alt={buttonTitle} className="w-full" />
        )}
        {children}
        <p className="text-primary-500 font-bold text-lg mt-4 mr-2">{title}</p>
      </div>
      <Button
        id={id || "register"}
        title={buttonTitle}
        variant="primary"
        fillStyle="fill"
        className="mt-3 md:w-1/3 w-full"
        target={target}
      />
    </Link>
  );
}
