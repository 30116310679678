import { capitalize, get, pick, toLower } from "lodash";

import {
  ASSET_REGISTRATION_INPUT_FIELDS,
  CONTENTFUL_BRAND_IDEAL_HEATING_NORTH_AMERICA,
  CONTENTFUL_BRAND_TRIANGLE_TUBE,
  COUNTRY_CODE_CA,
  COUNTRY_CODE_IE,
  COUNTRY_CODE_UK,
  COUNTRY_CODE_US,
  LOCALE_CA,
  LOCALE_IE,
  LOCALE_UK,
  LOCALE_US,
  SALESFORCE_PREFERRED_BOILER_BRAND_IDEAL,
  SALESFORCE_PREFERRED_BOILER_BRAND_TRIANGLE_TUBE,
} from "support/constants";
import { useEffect, useState } from "react";
import { API } from "aws-amplify";

export const conditionalClass = (cond, tailwindClass) => {
  if (cond) {
    return tailwindClass;
  }

  return "";
};

export const parseModelName = (modelName) => {
  if (modelName) {
    return modelName
      .split(" ")
      .map((x) => capitalize(toLower(x)))
      .join(" ");
  }

  return "Unknown";
};

export const trimFields = (fieldValues) => {
  Object.keys(fieldValues).forEach((key) => {
    if (typeof fieldValues[key] == "string") {
      fieldValues[key] = fieldValues[key].trim();
    }
  });
};

export const parseAddress = (houseNumber, addressFields) => {
  return `${houseNumber || ""} ${addressFields
    .filter((item) => item)
    .join(", ")}`.trim();
};

export const productPointsKeyForCountry = (countryCode) => {
  switch (countryCode) {
    case COUNTRY_CODE_IE:
      return "pointsRequiredRoi";
    case COUNTRY_CODE_US:
      return "pointsRequiredUs";
    case COUNTRY_CODE_CA:
      return "pointsRequiredCa";
    case COUNTRY_CODE_UK:
    default:
      return "pointsRequired";
  }
};

export const determineBasePoints = ({ countryCode, product }) => {
  const key = productPointsKeyForCountry(countryCode);
  const pointsForCountry = product[key];
  return pointsForCountry !== null ? pointsForCountry : product.pointsRequired;
};

export const getPoints = ({
  countryCode,
  isMaxAi,
  product,
  pointsAdjustment = 0,
}) => {
  const basePoints =
    determineBasePoints({
      countryCode,
      product,
    }) + pointsAdjustment;

  const maxAiPoints =
    product.pointsRequiredMaxAi !== null
      ? product.pointsRequiredMaxAi + pointsAdjustment
      : null;

  const appliedPoints =
    isMaxAi && maxAiPoints !== null ? maxAiPoints : basePoints;

  return { basePoints, maxAiPoints, appliedPoints };
};

export const sanitizeAssetRegistration = (registration) => {
  return pick(registration, ASSET_REGISTRATION_INPUT_FIELDS);
};

export const fetchDataFromRemoteUrls = (key, url, setDropdownPickerUrls) => {
  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .then((response) => setDropdownPickerUrls({ [key]: response[key] }));
};

export const checkHidingRules = (hiding_rules, fieldValues) => {
  if (hiding_rules) {
    let vals = {};
    for (const { id, value } of hiding_rules) {
      vals[id] = fieldValues[id] === value || fieldValues[id] === Number(value);
    }

    return Object.values(vals).every(Boolean);
  }
  return true;
};

export function IsMobile() {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return width <= 768;
}

export function canRegisterBoilers(locale, gasSafeNumber) {
  if ([LOCALE_IE, LOCALE_UK].includes(locale)) {
    return !!gasSafeNumber;
  }
  return true;
}

export const triggerBase64download = (base64data, fileName) => {
  const linkSource = `data:application/pdf;base64,${base64data}`;
  const downloadLink = document.createElement("a");

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.target = "_self";

  document.body.appendChild(downloadLink);

  downloadLink.click();
};

export const determineEphemeralDownloadUrl = async function (downloadNonce) {
  const apiEndpoint = await API.endpoint("UnauthenticatedAPI");
  return `${apiEndpoint}/ephemeral-download/${downloadNonce}`;
};

export const triggerEphemeralDownload = (url) => {
  const downloadLink = document.createElement("a");

  downloadLink.href = url;
  downloadLink.target = "_self";

  document.body.appendChild(downloadLink);

  downloadLink.click();
};

export const isNorthAmericanUser = (countryCode) => {
  return [COUNTRY_CODE_CA, COUNTRY_CODE_US].includes(countryCode);
};

export const isNorthAmericanLocale = (locale) => {
  return [LOCALE_CA, LOCALE_US].includes(locale);
};

function isNorthAmericanBrand(brandName) {
  return [
    CONTENTFUL_BRAND_IDEAL_HEATING_NORTH_AMERICA,
    CONTENTFUL_BRAND_TRIANGLE_TUBE,
  ].includes(brandName);
}

/**
 * Salesforce also has "I have no preference/I do not install boilers"
 * Only for north american users
 */
function userHasBrandPreference(preferredBoilerBrand) {
  return [
    SALESFORCE_PREFERRED_BOILER_BRAND_TRIANGLE_TUBE,
    SALESFORCE_PREFERRED_BOILER_BRAND_IDEAL,
  ].includes(preferredBoilerBrand);
}

/**
 * @see gatsby-node.js for page generation and src/templates/MarketingMaterials.js for implementation
 * countryCode - Salesforce country code on account
 * preferredBoilerBrand - Preferred boiler brand in Salesforce, currently only used for US
 */
export const displayBrandBasedOnPreference = (
  brandName,
  countryCode,
  preferredBoilerBrand
) => {
  if (!isNorthAmericanBrand(brandName)) {
    return !isNorthAmericanUser(countryCode);
  }

  if (!userHasBrandPreference(preferredBoilerBrand)) {
    return true;
  }

  switch (preferredBoilerBrand) {
    case SALESFORCE_PREFERRED_BOILER_BRAND_IDEAL:
      return brandName === CONTENTFUL_BRAND_IDEAL_HEATING_NORTH_AMERICA;
    case SALESFORCE_PREFERRED_BOILER_BRAND_TRIANGLE_TUBE:
      return brandName === CONTENTFUL_BRAND_TRIANGLE_TUBE;
    default:
      return false;
  }
};

export const extractActiveRecallsFromFormData = (formData) => {
  const assetInfoPaths = [
    "assetInfo",
    "controlAssetInfo",
    "cylinderAssetInfo",
    "filterAssetInfo",
    "lpgInfo",
  ];
  const activeRecalls = [];
  for (const path of assetInfoPaths) {
    const assetData = get(formData, path);
    if (assetData && assetData.activeRecall) {
      const recallData = pick(assetData, ["serialNumber", "modelName"]);
      activeRecalls.push(recallData);
    }
  }

  return activeRecalls;
};

export const displayMerchantDetailsForm = (countryCode) => {
  return !isNorthAmericanUser(countryCode);
};

export const showSafetyUpgrades = (countryCode) => {
  if (process.env.GATSBY_FEATURE_SAFETY_UPGRADES_ENABLED === "false") {
    return false;
  }

  return countryCode === COUNTRY_CODE_UK;
};

export const recallAssetsClaimable = () => {
  const claimableEndDate = recallAssetClaimableDate();
  if (claimableEndDate === null) {
    return true;
  }
  return new Date() <= claimableEndDate;
};

export const recallAssetClaimableDate = () => {
  const claimableDateEnv = process.env.GATSBY_RECALL_CLAIM_END_DATE;
  if (!claimableDateEnv) {
    return null;
  }
  return new Date(claimableDateEnv);
};
