import React from "react";

export default function InformationBox({
  title = "Information",
  description,
  children,
  ...props
}) {
  return (
    <section className="flex flex-col w-full p-8 mt-8 bg-base-50">
      <h1 className="mb-2 text-lg font-bold text-primary-500">{title}</h1>
      {children}
    </section>
  );
}
