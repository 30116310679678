import React, { useState, useCallback } from "react";
import Webcam from "react-webcam";
import { Close } from "../icons";
import { Button } from "components";
import UploadImageFile from "./UploadImageFile";
import { useCypressWarning, useGraphql, useApp } from "hooks";
import { getBenchmarkPresignedUrlQuery } from "api/queries";
import axios from "axios";

const UploadImage = ({
  id,
  title,
  width = "100%",
  height = "100%",
  facingMode = "environment",
  torch,
  videoConstraints,
  onChange,
}) => {
  useCypressWarning(id, `id missing for ${title} button component`);

  const { executeQuery: executeGetBenchmarkPresignedUrlQuery } = useGraphql(
    getBenchmarkPresignedUrlQuery
  );

  const { addMessage } = useApp();

  const webcamRef = React.useRef(null);
  const [image, setImage] = useState("");
  const [showCamera, setShowCamera] = useState(false);
  const [uploadImage, setUploadImage] = useState(false);
  const [loading, setLoading] = useState(false);

  const capture = React.useCallback(async () => {
    const imageSrc = webcamRef?.current?.getScreenshot();
    const file = await fetch(imageSrc).then((response) => response.blob());
    changeImage(new File([file], "", { type: "image/jpeg" }));
  }, [webcamRef]);

  React.useEffect(() => {
    // Turn on the flashlight if prop is defined and device has the capability
    if (
      typeof torch === "boolean" &&
      navigator?.mediaDevices?.getSupportedConstraints().torch
    ) {
      const stream = webcamRef?.current?.video.srcObject;
      const track = stream?.getVideoTracks()[0]; // get the active track of the stream
      if (
        track &&
        track.getCapabilities().torch &&
        !track.getConstraints().torch
      ) {
        track
          .applyConstraints({
            advanced: [{ torch }],
          })
          .catch((err) => console.log(err));
      }
    }
  }, [torch]);

  const uploadFile = useCallback(
    async (url, file) => {
      return new Promise(() => {
        axios
          .put(url, file)
          .then(({ request }) => {
            if (onChange) {
              onChange(request.responseURL.split("?")[0]);
            }
          })
          .catch(() => {
            addMessage(
              "error",
              "An error occurred when uploading your image. Please try again."
            );
          });

        setLoading(false);
      });
    },
    [onChange]
  );

  const changeImage = useCallback(async (newImage) => {
    setImage(newImage);

    if (newImage === "" && onChange) {
      onChange(newImage);
    }

    if (newImage !== "") {
      setLoading(true);
      const { data } = await executeGetBenchmarkPresignedUrlQuery();
      await uploadFile(data.getBenchmarkPresignedUrl.presignedUrl, newImage);
    }
  }, []);

  return (
    <div className="mt-8 mb-6">
      <p className="text-white mb-4">{title}</p>

      {showCamera && (
        <div className="relative bg-primary-50 h-80 w-full rounded-md">
          <div
            className="absolute top-2 right-2 cursor-pointer z-10"
            onClick={() => {
              setShowCamera(false);
              changeImage("");
            }}
          >
            <Close />
          </div>
          {image === "" ? (
            <Webcam
              audio={false}
              height={height}
              width={width}
              ref={webcamRef}
              className="rounded-md"
              screenshotFormat="image/jpeg"
              onUserMediaError={(e) => console.log("error: ", e)}
              videoConstraints={
                videoConstraints || {
                  facingMode,
                }
              }
            />
          ) : (
            <img src={URL.createObjectURL(image)} alt="Captured preview" />
          )}
        </div>
      )}

      <div className="my-4">
        <Button
          id="show-camera"
          title={
            image !== "" && !uploadImage
              ? "Retake photo"
              : showCamera
              ? "Capture image"
              : "Take photo"
          }
          variant="offWhite"
          fillStyle="outline"
          loading={!uploadImage && loading}
          onClick={() => {
            if (showCamera && image === "") {
              capture();
            } else {
              changeImage("");
              setUploadImage(false);
              setShowCamera(true);
            }
          }}
        />
        <p
          className="my-4 text-white text-sm underline cursor-pointer"
          onClick={() => {
            if (!uploadImage) {
              setShowCamera(false);
              setUploadImage(true);
              if (image !== "") {
                changeImage("");
              }
            }
          }}
        >
          Or upload image
        </p>
        {uploadImage && (
          <UploadImageFile
            image={image}
            setImage={changeImage}
            loading={uploadImage && loading}
          />
        )}
      </div>
    </div>
  );
};

export default UploadImage;
