import React from "react";
import { useCypressWarning } from "hooks";

export default function Radio({
  id,
  title,
  value,
  checked,
  helpText,
  renderTitle,
  variant = "dark",
  disabled = false,
  radioColor,
  onChange,
}) {
  useCypressWarning(id, `id missing for ${title} radio component`);

  const labelTextClass = variant === "light" ? "text-black" : "text-white";

  return (
    <div>
      <div
        className="flex flex-row items-center"
        role="checkbox"
        aria-checked={checked}
        aria-labelledby={id}
      >
        <input
          id={id}
          type="radio"
          value={value}
          checked={checked}
          onChange={() => onChange(value)}
          className={`cursor-pointer ${radioColor}`}
          disabled={disabled}
        />
        <label
          id={id}
          data-cy={`${id}-label`}
          htmlFor={id}
          className={`cursor-pointer text-sm font-normal ${labelTextClass} ml-2`}
          onClick={() => onChange(value)}
        >
          {renderTitle ? renderTitle() : title}
        </label>
      </div>
      {!checked && helpText && (
        <p className="text-xs text-primary-100 mt-3">{helpText}</p>
      )}
    </div>
  );
}
