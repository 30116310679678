import React, { useCallback, useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import Button from "../Button/Button";
import Modal from "components/Modal/Modal";
import { getBenchmarkPresignedUrlQuery } from "api/queries";
import { useApp, useGraphql } from "hooks";
import axios from "axios";

const useCanvasSize = () => {
  const modalRef = useRef();

  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);

  const calculateSize = useCallback(() => {
    const modalWidth = modalRef.current ? modalRef.current.offsetWidth : 0;
    setCanvasWidth(Math.round(modalWidth * 0.8));
    setCanvasHeight(Math.round(modalWidth * 0.3));
  }, [modalRef.current]);

  useEffect(() => {
    calculateSize();
  }, [calculateSize]);

  useEffect(() => {
    window.addEventListener("resize", calculateSize);

    return () => window.removeEventListener("resize", calculateSize);
  }, []);

  return { modalRef, canvasWidth, canvasHeight };
};

export default function Signature({
  id,
  title,
  value,
  error,
  helpText,
  variant = "dark",
  onChange,
  ...props
}) {
  const { executeQuery: executeGetBenchmarkPresignedUrlQuery } = useGraphql(
    getBenchmarkPresignedUrlQuery
  );
  const { addMessage } = useApp();
  const { modalRef, canvasWidth, canvasHeight } = useCanvasSize();

  const [savedSignature, setSavedSignature] = useState(value || null);
  const [tempSignature, setTempSignature] = useState(null);
  const [edited, setEdited] = useState(false);
  const [modal, setModal] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [sigCanvas, setSigCanvas] = useState();
  const [loading, setLoading] = useState(false);

  const labelTextClass = variant === "light" ? "text-black" : "text-white";

  const uploadFile = async (url, file) => {
    return new Promise(() => {
      axios
        .put(url, file)
        .then(({ request }) => {
          const url = new URL(request.responseURL);
          const img = url.origin + url.pathname
        
          setSavedSignature(tempSignature);
          setTempSignature(null);
          onChange(img);
        })
        .catch(() => {
          addMessage(
            "error",
            "An error occurred when uploading your image. Please try again."
          );
        });

      closeModal();
      setLoading(false);
    });
  };

  const handleSaveSignature = async () => {
    setLoading(true);

    const { data } = await executeGetBenchmarkPresignedUrlQuery();
    const file = await fetch(tempSignature).then((response) => response.blob());

    await uploadFile(
      data.getBenchmarkPresignedUrl?.presignedUrl,
      new File([file], "", { type: "image/jpeg" })
    );
  };

  const handleOnEnd = () => {
    setTempSignature(sigCanvas.toDataURL());
    setIsTouched(true);
    setEdited(true);
  };

  const clearSignature = () => {
    onChange("");
    setIsTouched(false);
    setSavedSignature(null);
    setTempSignature(null);
    if (sigCanvas) sigCanvas.clear();
  };

  const openModal = () => {
    setIsTouched(false);
    clearSignature();
    setModal(true);
  };

  const closeModal = () => {
    setIsTouched(savedSignature ? true : false);
    setTempSignature(null);
    setModal(false);
  };

  useEffect(() => {
    if (edited) {
      setEdited(false);
    } else if (sigCanvas) {
      const signature = isTouched ? tempSignature : savedSignature;

      sigCanvas.clear();
      sigCanvas.fromDataURL(signature, {
        width: canvasWidth,
        height: canvasHeight,
      });
    }
  }, [
    sigCanvas,
    tempSignature,
    savedSignature,
    edited,
    setEdited,
    canvasWidth,
    canvasHeight,
  ]);

  return (
    <div>
      <label
        id={id}
        data-cy={id}
        className={`text-sm font-normal ${labelTextClass}`}
      >
        {title}
      </label>

      <button
        type="button"
        className={
          "w-full min-h-max flex-row items-center content-center mt-1 " +
          (savedSignature
            ? "bg-white"
            : `border ${error ? "border-error-500 border-2" : "border-white"}`)
        }
        onClick={(e) => {
          e.preventDefault();
          openModal();
        }}
      >
        {savedSignature && (
          <img
            src={savedSignature}
            alt="Signature Preview"
            width="320px"
            height="120px"
            className="cursor"
          />
        )}
        {!savedSignature && (
          <p className="text-sm font-normal text-center text-white w-full py-12">
            Please create a signature
          </p>
        )}
      </button>

      <Button
        id="open-modal"
        fillStyle="outline"
        variant="offWhite"
        title={"Create Signature"}
        onClick={(e) => {
          e.preventDefault();
          openModal();
        }}
        className="mt-4"
      />

      {helpText && !savedSignature && (
        <p className="text-xs text-primary-100 mt-3">{helpText}</p>
      )}

      {modal && (
        <Modal onClose={closeModal} showClose={true}>
          <div
            className="text-center w-11/12 bg-white opacity-100 m-auto p-8 rounded-md drop-shadow-md"
            ref={modalRef}
          >
            <div className="flex flex-col items-center">
              <p className="text-sm font-normal pb-2">{title}</p>
              <div className="bg-white border border-gray-300 mb-2">
                <SignatureCanvas
                  penColor="black"
                  canvasProps={{
                    className: "signature",
                    height: canvasHeight,
                    width: canvasWidth,
                  }}
                  ref={(ref) => {
                    setSigCanvas(ref);
                  }}
                  clearOnResize={false}
                  onEnd={handleOnEnd}
                />
              </div>
              <div className="mt-4 md:space-x-4 space-y-4 md:space-y-0 md:flex">
                <Button
                  id="clear-canvas"
                  fillStyle="fill"
                  variant="primary"
                  title="Clear"
                  onClick={(e) => {
                    e.preventDefault();
                    clearSignature();
                  }}
                  className="w-full md:w-auto"
                />
                <Button
                  id="save-canvas"
                  fillStyle="fill"
                  variant={isTouched ? "primary" : "disabled"}
                  title="Save Signature"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSaveSignature();
                  }}
                  disabled={!isTouched}
                  className="w-full md:w-auto"
                  loading={loading}
                />
                <Button
                  id="close-canvas-modal"
                  fillStyle="fill"
                  variant="primary"
                  title="Close"
                  onClick={(e) => {
                    e.preventDefault();
                    closeModal();
                  }}
                  className="w-full md:w-auto"
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
