import IdealHeating from "../../images/brands/ideal-heating.svg";
import Gledhill from "../../images/brands/gledhill.svg";
import Atlantic from "../../images/brands/atlantic.svg";
import { LOCALE_UK } from "support/constants";
import Keston from "../../images/brands/keston.svg";
import React from "react";

export default function Brands({ locale }) {
  return (
    <section className="items-center justify-center w-full px-8 bg-primary md:flex py-14">
      <div>
        <img className="w-1/2 md:w-2/3" src={IdealHeating} alt="register" />
      </div>

      <div>
        <img className="w-1/2 md:w-2/3" src={Gledhill} alt="register" />
      </div>

      <div>
        <img className="w-1/2 md:w-2/3" src={Atlantic} alt="register" />
      </div>

      {locale === LOCALE_UK && (
        <div>
          <img className="w-1/2 md:w-2/3" src={Keston} alt="register" />
        </div>
      )}
    </section>
  );
}
