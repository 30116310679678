import React, { useState } from "react";
import { useCypressWarning } from "../../hooks";
import { Error, ShowPasswordToggle, HidePasswordToggle } from "../icons";
import Tooltip from "../Tooltip/Tooltip";

const getInputStateClasses = (variant, disabled, error) => {
  if (disabled) {
    if (variant === "light") {
      return "bg-base-50 border-base-300 text-base-500";
    } else {
      return "bg-primary-400 border-primary-500 text-base-100";
    }
  }

  if (error) {
    return "border-error-500 bg-error-50 outline-error-500 border-2 outline-2";
  }

  return "bg-white border-base-300 outline-primary-300 outline-1";
};

export default function Input({
  id,
  type,
  placeholder,
  error,
  title,
  subtitle,
  helpText,
  renderHelpText,
  value,
  disabled = false,
  onChange,
  trailingIcon,
  variant = "dark",
  tooltipMessage,
  onBlur,
  ...props
}) {
  useCypressWarning(id, `id missing for ${title} input component`);

  const labelTextClass = variant === "light" ? "text-black" : "text-white";
  const helpTextClass =
    variant === "light" ? "text-primary-800" : "text-primary-100";

  const showPasswordToggle = type === "password";
  const [inputType, setInputType] = useState(type);

  return (
    <div className="flex flex-col w-full relative">
      <label
        data-cy={`${id}-label`}
        htmlFor={id}
        className={`text-sm font-normal ${labelTextClass}`}
      >
        {title}
      </label>
      {tooltipMessage && (
        <div className="flex sm:hidden absolute items-center top-0 right-0">
          <Tooltip message={tooltipMessage}>
            <Error className="text-base-900" />
          </Tooltip>
        </div>
      )}
      {subtitle && (
        <p className={`text-xs mt-1 ${labelTextClass}`}>{subtitle}</p>
      )}
      <div className="flex flex-col relative">
        <input
          id={id}
          data-cy={`${id}-input`}
          name={id}
          type={inputType}
          className={`px-2 py-2 text-base-500 text-sm font-normal border mt-2 placeholder-base-500 ${getInputStateClasses(
            variant,
            disabled,
            error
          )}`}
          placeholder={placeholder}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
          onBlur={() => {
            if (onBlur) {
              onBlur();
            }
          }}
          value={value}
          disabled={disabled}
          {...props}
        />
        {trailingIcon && (
          <div className="absolute flex items-center mt-2 inset-y-0 right-2">
            {trailingIcon}
          </div>
        )}
        {tooltipMessage && (
          <div className="hidden sm:flex absolute items-center mt-2 inset-y-0 -right-8">
            <Tooltip message={tooltipMessage}>
              <Error className="text-base-900" />
            </Tooltip>
          </div>
        )}
        {showPasswordToggle && (
          <div
            onClick={() => {
              inputType === "password" && value !== ""
                ? setInputType("text")
                : setInputType("password");
            }}
            className="sm:flex absolute items-center mt-5 md:mt-2 inset-y-0 -right-0 mr-3"
          >
            {inputType === "password" ? (
              <HidePasswordToggle />
            ) : (
              <ShowPasswordToggle />
            )}
          </div>
        )}
      </div>
      {(helpText || renderHelpText) && (
        <p className={`text-xs ${helpTextClass} mt-3`}>
          {renderHelpText ? renderHelpText() : helpText}
        </p>
      )}
    </div>
  );
}
