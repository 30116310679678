import React, { useCallback, useState, useEffect } from "react";
import { ArrowLeft, ArrowRight, Loading } from "../icons";
import {
  endOfMonth,
  isSunday,
  isMonday,
  isToday,
  nextSunday,
  previousMonday,
  getMonth,
  getYear,
  addDays,
  getDate,
  compareAsc,
  addYears,
  subYears,
  startOfMonth,
  compareDesc,
} from "date-fns";
import { navigate } from "gatsby";
import { getBoilerServiceReminders } from "../../api/queries";
import { useGraphql, useQueryParams } from "../../hooks";
import { debounce, get } from "lodash";

export default function ServiceReminderCalendar() {
  const { loading, executeQuery: executeGetBoilerServiceReminders } =
    useGraphql(getBoilerServiceReminders);

  const { month: selectedMonth, year: selectedYear } = useQueryParams();

  const monthNames = [
    { full: "January", short: "Jan" },
    { full: "February", short: "Feb" },
    { full: "March", short: "Mar" },
    { full: "April", short: "Apr" },
    { full: "May", short: "May" },
    { full: "June", short: "Jun" },
    { full: "July", short: "Jul" },
    { full: "August", short: "Aug" },
    { full: "September", short: "Sep" },
    { full: "October", short: "Oct" },
    { full: "November", short: "Nov" },
    { full: "December", short: "Dec" },
  ];

  const [month, setMonth] = useState(
    parseInt(selectedMonth - 1) || getMonth(new Date())
  );
  const [year, setYear] = useState(
    parseInt(selectedYear) || getYear(new Date())
  );

  const [selectedDay, setSelectedDay] = useState();

  useEffect(() => {
    executeGetBoilerServiceReminders({
      month: month + 1,
      year: year,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const calendarStartDate = (year, month) => {
    const monthStartDate = new Date(year, month, 1);
    return isMonday(monthStartDate)
      ? monthStartDate
      : previousMonday(monthStartDate);
  };

  const calendarEndDate = (year, month) => {
    const monthEndDate = endOfMonth(new Date(year, month, 1));
    return isSunday(monthEndDate) ? monthEndDate : nextSunday(monthEndDate);
  };

  const calendar = useCallback(
    (calendarStartDate, calendarEndDate, month, events) => {
      let calendarDays = [];
      let currentDay = calendarStartDate;
      while (currentDay < calendarEndDate) {
        const daysRegistrations = events.filter(
          ({ installationDate }) =>
            getDate(currentDay) === getDate(new Date(installationDate)) &&
            getMonth(currentDay) === month
        );
        calendarDays.push({
          date: currentDay,
          isCurrentMonth: getMonth(currentDay) === month,
          isToday: isToday(currentDay),
          isSelected: false,
          events: daysRegistrations,
        });
        currentDay = addDays(currentDay, 1);
      }
      return calendarDays;
    },
    []
  );

  const [days, setDays] = useState(
    calendar(
      calendarStartDate(year, month),
      calendarEndDate(year, month),
      month,
      []
    )
  );

  useEffect(() => {
    const fetchInitialEvents = async () => {
      const result = await executeGetBoilerServiceReminders({
        month: month + 1,
        year: year,
      });

      const boilerServiceReminders = get(
        result,
        "data.getBoilerServiceReminders.items"
      );

      if (boilerServiceReminders) {
        setDays(
          calendar(
            calendarStartDate(year, month),
            calendarEndDate(year, month),
            month,
            boilerServiceReminders
          )
        );
      }
    };
    fetchInitialEvents();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const maxViewDate = endOfMonth(new Date(year, month));
  const maxCalendarDate = addYears(new Date(), 10);
  const maxCalendarDateDisabled =
    compareAsc(maxViewDate, maxCalendarDate) === 1;

  const minViewDate = startOfMonth(new Date(year, month));
  const minCalendarDate = subYears(new Date(), 10);
  const minCalendarDateDisabled =
    compareDesc(minViewDate, minCalendarDate) === 1;

  const handleMonthChange = useCallback(
    debounce(async (month, year, calendarStartDate, calendarEndDate) => {
      const result = await executeGetBoilerServiceReminders({
        month: month + 1,
        year: year,
      });

      const boilerServiceReminders = get(
        result,
        "data.getBoilerServiceReminders.items"
      );

      if (boilerServiceReminders) {
        setDays(
          calendar(
            calendarStartDate,
            calendarEndDate,
            month,
            boilerServiceReminders
          )
        );
      }
    }, 500),
    []
  );

  return (
    <div className="lg:flex lg:h-full lg:flex-col rounded-xl border border-base-100 bg-base-50">
      <div className="flex items-center justify-between py-4 px-6">
        <p className="md:hidden text-xl font-bold text-primary-500">
          {monthNames[month].short} {year}
        </p>
        <p className="hidden md:block text-xl font-bold text-primary-500">
          {monthNames[month].full} {year}
        </p>

        <div className="flex items-center">
          {loading && (
            <div className="mr-3">
              <Loading />
            </div>
          )}
          <div className="flex items-center border border-base-300 bg-white px-4 py-2">
            <div
              className="cursor-pointer"
              onClick={() => {
                if (!minCalendarDateDisabled && !loading) {
                  let newMonth, newYear;

                  if (month - 1 < 0) {
                    newMonth = 11;
                    newYear = year - 1;
                    setMonth(newMonth);
                    setYear(newYear);
                  } else {
                    newMonth = month - 1;
                    newYear = year;
                    setMonth(newMonth);
                  }

                  setDays(
                    calendar(
                      calendarStartDate(newYear, newMonth),
                      calendarEndDate(newYear, newMonth),
                      newMonth,
                      []
                    )
                  );

                  handleMonthChange(
                    newMonth,
                    newYear,
                    calendarStartDate(newYear, newMonth),
                    calendarEndDate(newYear, newMonth)
                  );
                  navigate(`?month=${newMonth + 1}&year=${newYear}`, {
                    replace: true,
                    state: {
                      pageYOffset: window.scrollY,
                    },
                  });
                }
              }}
            >
              <ArrowLeft
                size={16}
                colour={!minCalendarDateDisabled ? "#737373" : "#C7C7C7"}
              />
            </div>
            <p className="text-base-500 text-sm px-2 w-auto md:w-20 flex justify-center">
              <span className="md:hidden">{monthNames[month].short}</span>
              <span className="hidden md:block">{monthNames[month].full}</span>
            </p>
            <div
              className="cursor-pointer"
              onClick={() => {
                if (!maxCalendarDateDisabled && !loading) {
                  let newMonth, newYear;

                  if (month + 1 > 11) {
                    newMonth = 0;
                    newYear = year + 1;
                    setMonth(newMonth);
                    setYear(newYear);
                  } else {
                    newMonth = month + 1;
                    newYear = year;
                    setMonth(newMonth);
                  }

                  setDays(
                    calendar(
                      calendarStartDate(newYear, newMonth),
                      calendarEndDate(newYear, newMonth),
                      newMonth,
                      []
                    )
                  );

                  handleMonthChange(
                    newMonth,
                    newYear,
                    calendarStartDate(newYear, newMonth),
                    calendarEndDate(newYear, newMonth)
                  );
                  navigate(`?month=${newMonth + 1}&year=${newYear}`, {
                    replace: true,
                    state: {
                      pageYOffset: window.scrollY,
                    },
                  });
                }
              }}
            >
              <ArrowRight
                size={16}
                colour={!maxCalendarDateDisabled ? "#737373" : "#C7C7C7"}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
        <div className="grid grid-cols-7 gap-px border-b border-base-100 bg-base-100 text-center text-xs font-semibold leading-6 text-primary-500 lg:flex-none">
          <div className="bg-white py-3">
            M<span className="sr-only sm:not-sr-only">on</span>
          </div>
          <div className="bg-white py-3">
            T<span className="sr-only sm:not-sr-only">ue</span>
          </div>
          <div className="bg-white py-3">
            W<span className="sr-only sm:not-sr-only">ed</span>
          </div>
          <div className="bg-white py-3">
            T<span className="sr-only sm:not-sr-only">hu</span>
          </div>
          <div className="bg-white py-3">
            F<span className="sr-only sm:not-sr-only">ri</span>
          </div>
          <div className="bg-white py-3">
            S<span className="sr-only sm:not-sr-only">at</span>
          </div>
          <div className="bg-white py-3">
            S<span className="sr-only sm:not-sr-only">un</span>
          </div>
        </div>
        <div className="flex text-xs leading-6 text-primary-500 lg:flex-auto">
          <div className="hidden w-full lg:grid lg:grid-cols-7 lg:gap-px bg-base-100">
            {days.length > 0 &&
              days.map((day) => (
                <div
                  key={day.date}
                  className={`relative p-4 h-28 overflow-y-auto ${
                    day.isCurrentMonth ? "bg-white" : "bg-base-50 text-base-500"
                  }`}
                >
                  <time
                    dateTime={day.date}
                    className={
                      day.isToday
                        ? "flex h-6 w-6 items-center justify-center rounded-full bg-primary-500 font-semibold text-white"
                        : ""
                    }
                  >
                    {getDate(day.date)}
                  </time>
                  {day.events.length > 0 && !loading && (
                    <ol className="my-2">
                      {day.events.map((event) => (
                        <li key={event.id}>
                          <div
                            onClick={() =>
                              navigate(
                                `/app/my-registrations-history/details/${event.id}`
                              )
                            }
                            className="flex items-center cursor-pointer mb-1"
                          >
                            <p className="flex-auto truncate text-xs text-primary-500 mr-3">
                              {event.productName}
                            </p>
                            <p className="text-base-500 text-xs ml-auto">
                              View
                            </p>
                          </div>
                        </li>
                      ))}
                    </ol>
                  )}
                </div>
              ))}
          </div>
          <div className="isolate grid w-full grid-cols-7 gap-px lg:hidden bg-base-100">
            {days.length > 0 &&
              days.map((day, i) => (
                <div
                  key={day.date}
                  className={`${
                    day.isSelected
                      ? "bg-primary-50"
                      : day.isCurrentMonth
                      ? "bg-white"
                      : "bg-base-50 text-base-500"
                  } flex flex-col py-2 px-3 h-16`}
                  onClick={() => {
                    setSelectedDay(day.events);
                    const newCalendarDays = days.map((day, index) => {
                      if (index === i) {
                        return { ...day, isSelected: true };
                      } else {
                        return { ...day, isSelected: false };
                      }
                    });
                    setDays(newCalendarDays);
                  }}
                >
                  <time
                    dateTime={day.date}
                    className={`${
                      day.isToday &&
                      "flex h-5 w-5 items-center justify-center rounded-full bg-primary-500 text-white font-semibold mt-1"
                    } ml-auto mb-1
                  `}
                  >
                    {getDate(day.date)}
                  </time>
                  {day.events.length > 0 && !loading && (
                    <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                      {day.events.map((event) => (
                        <span
                          key={event.id}
                          className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-primary-500"
                        />
                      ))}
                    </span>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>

      {selectedDay && selectedDay.length > 0 && (
        <div className="mt-4 rounded-b-xl g bg-base-50 p-2 lg:hidden">
          <ol className="divide-y divide-base-100 overflow-hidden rounded-lg bg-white text-xs shadow ring-1 ring-black ring-opacity-5">
            {selectedDay.map((event) => (
              <li
                key={event.id}
                className="p-4 pr-6 focus-within:bg-base-50 hover:bg-base-50"
              >
                <div
                  onClick={() =>
                    navigate(
                      `/app/my-registrations-history/details/${event.id}`
                    )
                  }
                  className="flex items-center cursor-pointer mb-1"
                >
                  <p className="truncate text-xs text-primary-500 mr-3">
                    {event.productName}
                  </p>
                  <p className="text-base-500 text-xs ml-auto">View</p>
                </div>
              </li>
            ))}
          </ol>
        </div>
      )}
    </div>
  );
}
