import { API, Auth } from "aws-amplify";
import Resizer from "react-image-file-resizer";

async function getJwtToken() {
  const currentSession = await Auth.currentSession();
  return currentSession.getIdToken().getJwtToken();
}

export async function uploadFile(transactionId, fileData) {
  const jwtToken = await getJwtToken();

  const { key } = await API.post("AuthenticatedAPI", "/uploads", {
    body: {
      transactionId,
      ...fileData,
    },
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  });

  return key;
}

export async function uploadToS3(transactionId, file) {
  const data = await getFileDataAsBase64(file);
  const fileData = {
    filename: file.name,
    dataUrl: data,
  };
  const s3Key = await uploadFile(transactionId, fileData);

  return {
    filename: file.name,
    s3Key,
  };
}

export async function deleteFromS3({ filename, s3Key }) {
  const jwtToken = await getJwtToken();
  const path = `/uploads/${s3Key}`;

  return await API.del("AuthenticatedAPI", path, {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  });
}

async function getFileDataAsBase64(file) {
  if (file.type.includes("image")) {
    return resizeFile(file);
  }
  return convertFileToBase64(file);
}

async function resizeFile(file) {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      800,
      800,
      "JPEG",
      90,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });
}

async function convertFileToBase64(file) {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  return new Promise((resolve, reject) => {
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
