import React, { useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import ShoppingCart from "../ShoppingCart/ShoppingCart";
import { Logo, Logout, Menu } from "../icons";
import { useAuth } from "../../hooks";
import { LanguageSwitcher } from "../index";

export default function Header({
  username,
  shoppingCartItemCount,
  hideCart = false,
  isApproved,
  onLogout,
  onToggleMenu,
}) {
  const maxLogoSize = "w-32 sm:w-64";
  const [logoSize, setLogoSize] = useState(maxLogoSize);
  const { isLoggedIn, loadingSession } = useAuth();
  let maxDocumentHeight = 0;

  const handleScroll = () => {
    const currentHeight = document.body.scrollHeight;
    if (currentHeight > maxDocumentHeight) maxDocumentHeight = currentHeight;

    const position = window.pageYOffset;
    const changeLogoAtPosition = 80 - (maxDocumentHeight - currentHeight);
    if (position > changeLogoAtPosition) {
      setLogoSize("w-32");
    } else {
      setLogoSize(maxLogoSize);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="sticky top-0 z-30 w-full px-8 py-4 bg-primary-500">
      <div className="container flex flex-row mx-auto">
        {onToggleMenu && (
          <button
            data-cy="header-toggle-menu"
            className="flex items-center mr-6 sm:hidden"
            onClick={onToggleMenu}
          >
            <Menu />
          </button>
        )}
        <div
          className="flex items-center mr-auto cursor-pointer"
          onClick={() =>
            isApproved ? navigate("/app/dashboard") : navigate("/")
          }
        >
          <Logo
            className={`transition-all ease-in-out duration-50 ${logoSize}`}
          />
        </div>
        {isLoggedIn && (
          <div className="flex flex-row items-center">
            {!hideCart && (
              <div className="mr-0 sm:mr-4">
                <ShoppingCart itemCount={shoppingCartItemCount} />
              </div>
            )}
            <p data-testid="user-greeting" className="hidden mr-2 sm:block">
              <span className="text-sm text-white">Hello, </span>
              <span className="text-sm font-semibold text-white">
                {username}
              </span>
            </p>
            <button
              className="hidden sm:block"
              data-cy="logout"
              aria-label="logout"
              onClick={onLogout}
            >
              <Logout />
            </button>
          </div>
        )}
        {!isLoggedIn && !loadingSession && (
          <div>
            <div className="flex flex-row items-center">
              <Link
                to="/register"
                data-cy="register"
                className="text-sm font-bold text-white"
              >
                Join
              </Link>
              <p className="mx-3 font-bold text-white">|</p>
              <Link
                to="/login"
                data-cy="login"
                className="text-sm font-bold text-white"
              >
                Login
              </Link>
            </div>
            {process.env.GATSBY_FEATURE_US_CAN_ENABLED === "true" && (
              <div className="hidden sm:block mt-3">
                <LanguageSwitcher variant="default" />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
