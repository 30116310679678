import React from "react";
import { Link } from "gatsby";
import { ShoppingCart as ShoppingCartIcon } from "../icons";

export default function ShoppingCart({ itemCount }) {
  return (
    <Link to="/app/basket/view" data-cy="shopping-cart">
      <div className="relative bg-primary-400 p-2 rounded-lg">
        <ShoppingCartIcon />
        {itemCount > 0 && (
          <div className="absolute -top-1/4 -right-1/4 px-1 min-w-4 bg-accent-500 rounded-full text-xs text-center">
            {itemCount}
          </div>
        )}
      </div>
    </Link>
  );
}
