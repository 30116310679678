const operators = ["+", "/", "-", "*", ">", "<"];

const OPEN_PAREN = "(";
const CLOSE_PAREN = ")";

export function evaluate(input, variables) {
  const expression = replaceVariables(input, variables);
  const reversePolish = shuntingYard(expression);

  return evaluatePostfix(reversePolish);
}

function replaceVariables(expression, variables) {
  return Object.entries(variables).reduce((acc, [k, v]) => {
    return acc.replace(k, v);
  }, expression);
}

/**
 * Shunting yard algorithm. This basically tokenises the input and converts
 * it to reverse polish notation so it's easier to evaluate.
 *
 * As an example:
 *   3 + 4 * 2 / ( 1 - 5 ) ^ 2 ^ 3
 * would be converted to:
 *   [3, 4, 2, *, 1, 5, -, 2, 3, ^, ^, /, +]
 */
function shuntingYard(input) {
  const outputQueue = [];
  const operatorStack = [];

  const precedence = {
    "||": 1,
    "&&": 2,
    ">": 3,
    ">=": 3,
    "<": 3,
    "<=": 3,
    "==": 4,
    "!=": 4,
    "+": 5,
    "-": 5,
    "*": 6,
    "/": 6,
    "^": 7,
  };

  const associativity = {
    "||": "left",
    "&&": "left",
    ">": "left",
    ">=": "left",
    "<": "left",
    "<=": "left",
    "==": "left",
    "!=": "left",
    "+": "left",
    "-": "left",
    "*": "left",
    "/": "left",
    "^": "right",
  };

  const tokens = input.match(
    /([0-9\.]+|\+|\-|\*|\/|\^|\(|\)|>=|<=|>|<|==|!=|&&|\|\|)/g
  );

  tokens.forEach((token) => {
    if (/^[0-9\.]+$/.test(token)) {
      outputQueue.push(parseFloat(token));
    } else if (/^(>=|<=|>|<|==|!=|\|\||&&|\+|\-|\*|\/|\^)$/.test(token)) {
      while (
        operatorStack.length > 0 &&
        operatorStack[operatorStack.length - 1] !== "(" &&
        ((associativity[token] === "left" &&
          precedence[token] <=
            precedence[operatorStack[operatorStack.length - 1]]) ||
          (associativity[token] === "right" &&
            precedence[token] <
              precedence[operatorStack[operatorStack.length - 1]]))
      ) {
        outputQueue.push(operatorStack.pop());
      }
      operatorStack.push(token);
    } else if (token === "(") {
      operatorStack.push(token);
    } else if (token === ")") {
      while (
        operatorStack.length > 0 &&
        operatorStack[operatorStack.length - 1] !== "("
      ) {
        outputQueue.push(operatorStack.pop());
      }
      if (operatorStack.length === 0) {
        throw new Error("Mismatched parentheses");
      }
      operatorStack.pop();
    }
  });

  while (operatorStack.length > 0) {
    const operator = operatorStack.pop();
    if (operator === "(") {
      throw new Error("Mismatched parentheses");
    }
    outputQueue.push(operator);
  }

  return outputQueue;
}

function evaluatePostfix(postfix) {
  const stack = [];

  postfix.forEach((token) => {
    if (typeof token === "number") {
      stack.push(token);
    } else {
      const rightOperand = stack.pop();
      const leftOperand = stack.pop();
      let result;
      switch (token) {
        case "+":
          result = leftOperand + rightOperand;
          break;
        case "-":
          result = leftOperand - rightOperand;
          break;
        case "*":
          result = leftOperand * rightOperand;
          break;
        case "/":
          result = leftOperand / rightOperand;
          break;
        case "^":
          result = Math.pow(leftOperand, rightOperand);
          break;
        case ">":
          result = leftOperand > rightOperand;
          break;
        case ">=":
          result = leftOperand >= rightOperand;
          break;
        case "<":
          result = leftOperand < rightOperand;
          break;
        case "<=":
          result = leftOperand <= rightOperand;
          break;
        case "==":
          result = leftOperand == rightOperand;
          break;
        case "!=":
          result = leftOperand != rightOperand;
          break;
        case "&&":
          result = leftOperand && rightOperand;
          break;
        case "||":
          result = leftOperand || rightOperand;
          break;
      }
      stack.push(result);
    }
  });

  if (stack.length !== 1) {
    throw new Error("Invalid expression");
  }

  return stack.pop();
}
