import React from "react";
import { Email, Tick, Warning } from "../icons";
import Button from "../Button/Button";

const Icon = ({ icon }) => {
  switch (icon) {
    case "tick":
      return (
        <div className="flex items-center m-auto rounded-full w-14 aspect-1 bg-success-100">
          <Tick fill="#22C55E" className="m-auto" />
        </div>
      );
    case "warning":
      return (
        <div className="flex items-center m-auto rounded-full w-14 aspect-1 bg-warning-100">
          <Warning fill="#F59E0B" className="m-auto" />
        </div>
      );
    case "email":
      return (
        <div className="flex items-center m-auto rounded-full w-14 aspect-1 bg-success-100">
          <Email className="m-auto" />
        </div>
      );
    case "none":
      return null;
    default:
      console.warn("Modal icon is not supported");
      return null;
  }
};

const Prompt = ({
  onCancel,
  onClose,
  show = true,
  title = "Placeholder",
  icon = "tick",
  content,
  cancelActionText = "Cancel action",
  actionText = "Action button",
  onAction,
  loading = false,
  loadingCancel = false,
  disabledCancel = false,
}) => {
  if (!show) {
    return null;
  }

  if (!onCancel) {
    onCancel = onClose;
  }

  return (
    <section
      className="fixed top-0 bottom-0 left-0 right-0 z-10 flex items-center"
      onClick={onClose}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="w-5/6 p-8 m-auto text-center bg-white opacity-100 md:w-1/2 lg:w-2/5 rounded-md drop-shadow-md"
      >
        {icon && <Icon icon={icon} />}
        <h1 className="my-4 text-xl font-bold text-black">{title}</h1>
        {content}
        <div className="mt-4 space-y-6 md:space-y-0 md:flex md:space-x-4">
          {onCancel && (
            <Button
              id="cancel-action"
              title={cancelActionText}
              loading={loadingCancel}
              disabled={disabledCancel}
              className={`w-full ${onAction ? "md:w-1/2" : "md:w-full"}`}
              variant={disabledCancel ? "disabled" : "base"}
              onClick={onCancel}
            />
          )}
          {onAction && (
            <Button
              id="action"
              title={actionText}
              loading={loading}
              className={`w-full ${onCancel ? "md:w-1/2" : "md:w-full"}`}
              onClick={onAction}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default Prompt;
