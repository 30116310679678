import { isEmpty, filter } from "lodash";

export const filterAnnouncementsForUser = (user, isMaxAi, announcements) => {
  return filter(announcements, (announcement) => {
    return (
      isEmpty(announcement.showTo) ||
      (isEmpty(user) && announcement.showTo?.includes("Logged out users")) ||
      (!isEmpty(user) && announcement.showTo.includes("All Logged in users")) ||
      (user.band === "bronze" &&
        announcement.showTo.includes("Logged in Bronze users")) ||
      (user.band === "silver" &&
        announcement.showTo.includes("Logged in Silver users")) ||
      (user.band === "gold" &&
        announcement.showTo.includes("Logged in Gold users")) ||
      (isMaxAi && announcement.showTo.includes("Logged in MaxAI users")) ||
      (user.maxAiStatus === "Eligible" &&
        announcement.showTo.includes("Logged in eligible for Max AI users")) ||
      (user.countryCode === "GB" &&
        announcement.showTo.includes("Logged in GB + NI")) ||
      (user.countryCode === "IE" &&
        announcement.showTo.includes("Logged in ROI")) ||
      (user.countryCode === "US" &&
        announcement.showTo.includes("Logged in US")) ||
      (user.countryCode === "CA" &&
        announcement.showTo.includes("Logged in Canada"))
    );
  });
};

export const smallScreenText = (announcement) => {
  if (announcement.shortBannerMessage) return announcement.shortBannerMessage;
  return announcement.bannerMessage;
};
