import { checkHidingRules } from "support/helpers";

export default function validation(items, fieldValues) {
  let validationSchema = {};

  const constructRule = (object) => {
    if (object.validation) {
      if (object.optional === false) {
        return "required|" + object.validation;
      } else {
        return object.validation;
      }
    } else {
      if (!object.optional) {
        return "required";
      }
    }
  };

  const skipThisLoop = (obj) => {
    if (obj.hasOwnProperty("hiding_rules")) {
      return !checkHidingRules(obj.hiding_rules, fieldValues);
    }
    if (obj.hasOwnProperty("form_type")) {
      return obj.form_type == 17;
    }
  };

  const validationRules = (object) => {
    for (let index in object) {
      let val = {};

      if (skipThisLoop(object)) continue;

      if (
        object.hasOwnProperty("id") &&
        (object.hasOwnProperty("validation") ||
          object.hasOwnProperty("optional"))
      ) {
        const rule = constructRule(object);

        if (skipThisLoop(object)) continue;

        val[object.id] = {
          name: object.title,
          validation: rule,
          value: null,
          form_type: object.form_type,
        };

        validationSchema = { ...validationSchema, ...val };
      }

      if (object[index] instanceof Object) validationRules(object[index]);
    }
  };

  validationRules(items);
  return validationSchema;
}
