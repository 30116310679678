import React from "react";
import { Search, Loading } from "../../components/icons";

export default function SearchBox({
  title,
  loading,
  value,
  onChange,
  ...props
}) {
  return (
    <div>
      <p className="text-xs font-normal text-primary-500 mt-5">{title}</p>

      <div className="flex flex-col relative">
        <input
          className="bg-white outline outline-base-300 outline-1 p-2 mt-2 text-primary-500 w-full"
          type="text"
          aria-label="search"
          placeholder="Search Here"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
        <div className="absolute flex items-center mt-2 inset-y-0 right-6">
          {loading ? <Loading /> : <Search />}
        </div>
      </div>
    </div>
  );
}
