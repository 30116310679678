import * as React from "react";
import { Link } from "gatsby";
import { useAnnouncements } from "../../hooks";
import { smallScreenText } from "../../services/announcements";

const AnnouncementBanner = ({ lessSpacing = false, hideBanner = false }) => {
  const { firstAnnouncement } = useAnnouncements();

  const topPlacementCSS = (lessSpacing) => {
    return lessSpacing ? "mb-4" : "mb-4 sm:mt-16";
  };

  return (
    <>
      {firstAnnouncement && !hideBanner && (
        <div className={`pt-4 ${topPlacementCSS(lessSpacing)}`}>
          <div className="px-2 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="p-2 bg-white rounded-lg shadow-lg sm:p-3">
              <div className="flex flex-wrap items-center justify-between">
                <div className="flex items-center w-4/5">
                  <span className="flex p-2 rounded-lg bg-primary-500">
                    <svg
                      className="w-6 h-6 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                      />
                    </svg>
                  </span>
                  <p className="ml-3 font-medium truncate text-primary-500">
                    <span className="md:hidden">
                      {smallScreenText(firstAnnouncement)}
                    </span>
                    <span className="hidden md:inline">
                      {firstAnnouncement.bannerMessage}
                    </span>
                  </p>
                </div>
                <div className="w-full mt-2 sm:order-2 sm:mt-0 sm:w-auto">
                  <Link
                    to="/announcements"
                    className="flex items-center justify-center px-4 py-2 text-sm font-medium text-primary-500 underline"
                  >
                    Learn more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AnnouncementBanner;
